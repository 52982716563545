import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import { keyframes } from "styled-components";

const AnswerResultPopup = ({ isAnswerValid }) => {
  return (
    <StyledAnswerResultPopup className="d-center rounded p-2" isAnswerValid={isAnswerValid}>
      {isAnswerValid ? <AiFillCheckCircle /> : <AiFillCloseCircle />}
      <span className="ms-1">{isAnswerValid ? "Bonne réponse !" : "Mauvaise réponse"}</span>
    </StyledAnswerResultPopup>
  );
};

const answerResultAnim = keyframes`
0% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) rotateY(0);
  }
`;

const StyledAnswerResultPopup = styled.div`
  text-align: center;

  color: ${({ isAnswerValid }) => (isAnswerValid ? "var(--bs-success)" : "var(--bs-danger)")};
  animation: ${answerResultAnim} 0.3s ease-out both;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  padding: 5px 8px;
`;

export default AnswerResultPopup;
