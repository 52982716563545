import ImageSliderView from "../../../Common/ImageSliderView/ImageSliderView";
import Img from "../../../Common/Img/Img";
import { IMAGE_URL } from "../../../config";
import useImageModal from "../../../hooks/useImageModal";

const ImageBlock = ({ data }) => {
  const { openModal } = useImageModal();

  const onImageClick = () => {
    openModal({
      title: data.title,
      content: (
        <ImageSliderView
          images={data.files}
          containerCN="h-100"
          imageMaxHeight="80vh"
          imageContainerHeight="90vh"
        />
      ),
    });
  };

  return (
    <div className="d-center flex-column w-100">
      <Img
        onClick={onImageClick}
        style={{ maxWidth: "100%", maxHeight: 300 }}
        src={IMAGE_URL + data.files[0].fileName}
      />
      <p className="mt-2 mb-0">
        <i>{data.files[0].legend}</i>
      </p>
    </div>
  );
};

export default ImageBlock;
