import styled from "styled-components";

const Fab = ({ children, ...props }) => {
  return (
    <FabContainer className="animate__animated animate__fadeInRight " {...props}>
      {children}
    </FabContainer>
  );
};

const FabContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 0px;
  z-index: 9;

  & .btn {
    height: 50px;
    width: 50px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
`;

export default Fab;
