import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { userPositionAtom } from "../../atoms/mapAtoms";
import { pulseOut } from "../../Common/Animations/Animations";
import UserPositionIcon from "./Marker/icons/UserPositionIcon";

const UserPositionMarker = ({ map }) => {
  const userPositionMarkerContainer = useRef(null);
  const userPositionMarker = useRef(null);
  const [userPosition] = useAtom(userPositionAtom);

  useEffect(() => {
    if (!map.current) return;
    if (!userPosition) return;
    if (!userPositionMarkerContainer.current) return;
    if (userPositionMarker.current) {
      userPositionMarker.current.setLngLat(userPosition);
    } else {
      userPositionMarker.current = new mapboxgl.Marker(userPositionMarkerContainer.current);
      userPositionMarker.current.setLngLat(userPosition).addTo(map.current);
    }
  }, [userPosition]);

  return (
    <StyledUserPositionMarker ref={userPositionMarkerContainer} className="user-position-marker">
      <UserPositionIcon />
    </StyledUserPositionMarker>
  );
};

const StyledUserPositionMarker = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 100ms;
  position: relative;

  svg {
    fill: whitesmoke;
    width: 25px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 85%;
    left: 50%;
    width: 100%;
    height: 4px;
    border: 2px solid whitesmoke;
    border-radius: 50%;
    animation-name: ${pulseOut};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: center;
    transform: translate(-50%, -50%);
  }
`;

export default UserPositionMarker;
