import styled from "styled-components";
import useGameStep from "../useGameStep";
import Typewriter from "typewriter-effect/dist/core";
import { useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

const StepDialogs = () => {
  const { currentStepRef, currentDialog, currentDialogRef, onNextDialog, onPrevDialog } =
    useGameStep();

  useEffect(() => {
    new Typewriter("#typewriter", {
      strings: currentDialogRef?.text,
      autoStart: true,
      delay: 5,
    });
  }, [currentDialog]);

  if (!currentDialogRef) return null;

  return (
    <StyledDialog onClick={onNextDialog}>
      {currentDialogRef.speaker && (
        <div style={{ color: "#710808", fontSize: 12 }}>
          <i>{currentDialogRef.speaker} :</i>
        </div>
      )}
      <div id="typewriter" style={{ minHeight: 100, whiteSpace: "pre-line" }}>
        {currentDialogRef.text}
      </div>

      <div className="d-flex justify-content-end w-100">
        {/* {currentDialog > 0 ? (
          <button className="btn btn-link d-none" onClick={onPrevDialog}>
            <AiOutlineArrowLeft />
          </button>
        ) : (
          <div></div>
        )} */}
        <button className="btn btn-link">Suite</button>
      </div>
    </StyledDialog>
  );
};

const StyledDialog = styled.div`
  margin: 0px 10px 20px;
  background: rgb(255 255 255 / 85%);
  padding: 20px;
  border-radius: 10px;
`;

export default StepDialogs;
