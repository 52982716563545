import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import Loader from "../Loader/Loader";
import { motion } from "framer-motion";

const Page = ({
  title,
  titleCN = "",
  isLoading,
  back = null,
  backIcon = null,
  start = null,
  end = null,
  contentContainerClassName = "",
  subTitle,
  bgImage,
  children,
}) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      className=" mb-5 pb-5"
      initial="initial"
      exit="out"
      animate="in"
      variants={pageVariants}
      transition={{ duration: 0.3 }}
      style={{
        backgroundImage: bgImage ? `url(${bgImage})` : "none",
      }}
    >
      {title && (
        <div className="mb-4 row mx-0 px-4">
          <div className="col-6 px-0 mb-2">
            {back && (
              <Link
                to={back}
                className="text-primary fs-3 p-0 h-100 text-center"
                style={{ position: "relative", zIndex: 2 }}
              >
                {backIcon || <IoMdArrowBack />}
              </Link>
            )}
          </div>
          <div className="col-6 px-0 mb-2 justify-content-end d-flex">
            {end}
          </div>
          <h1
            className={`title medium col-12 px-0 mb-0 text-center ${titleCN}`}
          >
            {title}
          </h1>
          {subTitle && <div className="col-12 text-center">{subTitle}</div>}
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className={contentContainerClassName}>{children}</div>
      )}
    </motion.div>
  );
};

export default Page;
