import { BsArrowRight } from "react-icons/bs";
import Button from "../../../Common/Button/Button";
import useCustomModal from "../../../hooks/useCustomModal";
import QuizzResults from "./QuizzResults";
import useQuizzResults from "./useQuizzResults";

const OpenQuizzResultsButton = () => {
  const { nbAnswers } = useQuizzResults();
  const { openModal } = useCustomModal();

  const openQuizzResults = () => {
    openModal({
      title: "Résultat des Quizz",
      content: <QuizzResults />,
    });
  };

  return !nbAnswers ? null : (
    <Button
      fullWidth
      color="primary"
      onClick={openQuizzResults}
      text="Voir les résultats des Quiz"
      icon={<BsArrowRight />}
    />
  );
};

export default OpenQuizzResultsButton;
