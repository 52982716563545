import { useEffect, useRef } from "react";
import styled from "styled-components";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useAtom } from "jotai";
import Dortoir2Icon from "./icons/Dortoir2Icon";
import MedecinIcon from "./icons/MedecinIcon";
import Dortoir1Icon from "./icons/Dortoir1Icon";
import { arcOut } from "../../../Common/Animations/Animations";
import { activeMarkerAtom } from "../../../atoms/mapAtoms";
import GareIcon from "./icons/GareIcon";
import EntrepotIcon from "./icons/EntrepotIcon";
import Lazaret2Icon from "./icons/Lazaret2Icon";
import CheminAnglaisIcon from "./icons/CheminAnglaisIcon";

const icons = {
  1: GareIcon,
  2: Dortoir1Icon,
  3: Dortoir2Icon,
  4: MedecinIcon,
  5: EntrepotIcon,
  6: Lazaret2Icon,
  7: CheminAnglaisIcon,
};

const Marker = ({ id, icon, title, lngLat, map }) => {
  const [activeMarker] = useAtom(activeMarkerAtom);

  const isActive = activeMarker == id;
  const markerEl = useRef(null);
  const markerObj = useRef(null);

  useEffect(() => {
    if (!map.current) return;
    if (!markerEl.current) return;
    if (markerObj.current) return;

    markerObj.current = new mapboxgl.Marker(markerEl.current);
    markerObj.current.setLngLat(lngLat).addTo(map.current);

    // markerEl.current.addEventListener("click", () => {
    //   map.current.panTo(lngLat);
    // });
  });

  useEffect(() => {
    if (!map.current) return;
    if (!markerEl.current) return;
    if (isActive) {
      map.current.panTo(lngLat);
    }
  });

  const IconComponent = icons[id];

  return (
    <div ref={markerEl}>
      <StyledMarker className={`${isActive ? "active" : ""}`}>
        <IconComponent />
        <span>{title}</span>
      </StyledMarker>
    </div>
  );
};

const StyledMarker = styled.div`
  width: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    fill: whitesmoke;
  }

  &.active {
    & span {
      color: var(--bs-warning);
    }
    & svg {
      fill: var(--bs-warning);
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-left: 2px solid whitesmoke;
      border-right: 2px solid whitesmoke;
      border-radius: 50%;
      animation-name: ${arcOut};
      animation-duration: 1s;
      animation-iteration-count: infinite;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-left: 2px solid whitesmoke;
      border-right: 2px solid whitesmoke;
      border-radius: 50%;
      animation-name: ${arcOut};
      animation-delay: 150ms;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }
  }

  span {
    padding-top: 7px;
    color: white;
    font-size: 15px;
  }
`;

export default Marker;
