import { useAtom } from "jotai";
import { nbQuizzBlocksAtom, quizzAnswersAtom, quizzPointsAtom } from "../../../hooks/atoms/atoms";

const useQuizzResults = () => {
  const [quizzAnswers] = useAtom(quizzAnswersAtom);
  const [quizzPoints] = useAtom(quizzPointsAtom);
  const [nbQuizz] = useAtom(nbQuizzBlocksAtom);

  const nbAnswers = Object.values(quizzAnswers).length;
  const nbGoodAnswers = quizzPoints;
  const nbWrongAnswers = nbAnswers - nbGoodAnswers;
  const nbUnanswered = nbQuizz - nbAnswers;
  const hasAnsweredAll = nbAnswers == nbQuizz;
  const hasAllGood = nbAnswers == nbGoodAnswers;

  const getAnswerStatusSentence = () => {
    let sentence = "";
    if (nbAnswers > 0) {
      if (hasAnsweredAll) {
        sentence = "Bien joué ! Tu as répondu à toutes les questions de Quiz !";
      } else {
        sentence = `Tu as répondu à ${nbAnswers} questions de Quiz sur ${nbQuizz}.`;
      }
    } else {
      sentence =
        "Tu n'as répondu à aucune question de Quiz pendant cette visite, peut-être la prochaine fois ?";
    }
    return sentence;
  };

  return {
    nbAnswers,
    nbGoodAnswers,
    nbWrongAnswers,
    nbQuizz,
    getAnswerStatusSentence,
    hasAnsweredAll,
    hasAllGood,
  };
};

export default useQuizzResults;
