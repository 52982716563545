import React, { useCallback } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import styled from "styled-components";
import { APP_URL } from "../../../../config";
import ControlInfo from "../ControlInfo/ControlInfo";

const CheckImage = ({
  image, //nom de l'image ex : plantes.jpg
  areas, //zones clicables ex : [ {id:1, x:0, y:0, w:50, h:50} ]
  onChange, //fonction qui met à jour la valeur selon la zone cliquée
  value, //contient la valeur actuelle (id de la zone cliquée)
  name, //nom de l'élément
  disabled,
}) => {
  const handleChange = useCallback(
    (id) => {
      if (disabled) return;
      onChange({
        target: {
          name: name,
          value: value == id ? "" : id,
        },
      });
    },
    [onChange]
  );

  return (
    <div>
      {!disabled && <ControlInfo text="Touchez une zone en pointillés pour la sélectionner" />}
      <div className="position-relative">
        <StyledImageContainer
          src={`${APP_URL}assets/images/game/${image}`}
          className="image-container shadow"
        />
        {areas.map((area) => {
          const isChecked = area.id == value;
          return (
            <StyledArea
              onClick={() => handleChange(area.id)}
              key={`area${area.id}`}
              params={{ ...area }}
              isChecked={isChecked}
            >
              {isChecked ? <AiOutlineCheck /> : <BsCircle />}
              <div className="area-name">{area.name}</div>
            </StyledArea>
          );
        })}
      </div>
    </div>
  );
};

const StyledImageContainer = styled.img`
  width: 100%;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
`;

const StyledArea = styled.div`
  position: absolute;
  border: ${({ isChecked }) => (isChecked ? "3px solid" : "2px dotted")} var(--bs-primary);
  backdrop-filter: grayscale(${({ isChecked }) => (isChecked ? "0" : "0.6")});
  border-radius: 10px;
  top: ${({ params }) => params.y}%;
  left: ${({ params }) => params.x}%;
  width: ${({ params }) => params.w}%;
  height: ${({ params }) => params.h}%;
  transition: 400ms;

  & svg {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 20px;
  }
  & .area-name {
    position: absolute;
    bottom: 2px;
    left: 0px;
    width: 100%;
    text-align: center;
    color: white;
  }
`;

export default CheckImage;
