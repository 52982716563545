import { BiCheck } from "react-icons/bi";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = ({
  icon,
  text,
  className,
  color,
  fullWidth,
  size,
  href,
  checked,
  iconSlot = "after",
  ...props
}) => {
  const getContent = () => {
    if (iconSlot == "after") {
      return (
        <>
          {text} {icon && <div className={text ? "ms-2" : ""}>{icon}</div>}
        </>
      );
    }

    return (
      <>
        {icon && <div className={text ? "me-2" : ""}>{icon}</div>} {text}
      </>
    );
  };

  if (href) {
    return (
      <Link
        to={href}
        className={`${className} btn-${color}   btn d-center flex-row rounded ${
          fullWidth ? "w-100" : ""
        } ${size ? "btn-" + size : ""}`}
        {...props}
      >
        {getContent()}
      </Link>
    );
  }

  return (
    <button
      className={`${className} btn-${color}   btn d-center flex-row rounded position-relative ${
        fullWidth ? "w-100" : ""
      } ${size ? "btn-" + size : ""}`}
      {...props}
    >
      {getContent()}
      {checked && <CheckIcon />}
    </button>
  );
};

export const CheckIcon = styled(BiCheck)`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-55%);
  font-size: 22px;
  color: white;
`;

export default Button;
