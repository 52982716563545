import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../config";

const dataService = {
  get: ({ url, onSuccess = () => {}, onError = () => {}, onFinish = () => {} }) => {
    axios
      .get(API_URL + url)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  post: ({ url, postData, onSuccess = () => {}, onError = () => {}, onFinish = () => {} }) => {
    axios
      .post(API_URL + url, postData)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  postWithOptions: ({
    url,
    postData,
    postOptions,
    onSuccess = () => {},
    onError = () => {},
    onFinish = () => {},
  }) => {
    axios
      .post(API_URL + url, postData, postOptions)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  patchWithOptions: ({
    url,
    postData,
    postOptions,
    onSuccess = () => {},
    onError = () => {},
    onFinish = () => {},
  }) => {
    axios
      .patch(API_URL + url, postData, postOptions)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  postFile: ({
    url,
    postData,
    onSuccess = () => {},
    onError = () => {},
    onFinish = () => {},
    setProgress = () => {},
  }) => {
    axios
      .post(API_URL + url, postData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          if (setProgress) {
            setProgress(percentCompleted);
          }
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      })
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },

  patch: ({
    url,
    postData = {},
    onSuccess = () => {},
    onError = () => {},
    onFinish = () => {},
  }) => {
    axios
      .patch(API_URL + url, postData)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  remove: ({ url, onSuccess = () => {}, onError = () => {}, onFinish = () => {} }) => {
    axios
      .delete(API_URL + url)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  removeNoConfirm: ({
    url,
    deleteData = {},
    onSuccess = () => {},
    onError = () => {},
    onFinish = () => {},
  }) => {
    axios
      .delete(API_URL + url, deleteData)
      .then((res) => onSuccess(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, onError);
      })
      .then(onFinish);
  },
  errorDisplay: (err, onError) => {
    if (err?.response?.data) {
      const { message } = err.response.data;
      if (typeof message == "string") {
        toast.error(message);
      } else {
        try {
          const arrayFromMessage = Object.keys(message);
          arrayFromMessage.forEach((messKey) => toast.error(`${messKey} : ${message[messKey]}`));
        } catch (err) {
          onError({ other: "Une erreur réseau est survenue" });
        }
      }
      onError(message);
    } else {
      onError({ other: "Une erreur est suvenue" });
    }
  },
};

export default dataService;
