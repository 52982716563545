import { AiOutlineHome } from "react-icons/ai";
import { BsArrowRight, BsFacebook, BsHouse, BsInstagram } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import Button from "../../Common/Button/Button";
import Page from "../../Common/Page/Page";
import OpenQuizzResultsButton from "./QuizzResults/OpenQuizzResultsButton";

const VisitEndPage = () => {
  return (
    <Page title="Fin de la visite" contentContainerClassName="px-4">
      <p>
        La visite guidée se termine ici, merci et bravo de l'avoir suivie
        jusqu'au bout !
      </p>
      <p className="mb-0">
        Nous espérons que vous avez fait des découvertes intéressantes sur
        l'histoire de La Réunion et des engagés.
      </p>
      <div className="mt-3 pb-4 mb-4">
        <OpenQuizzResultsButton />
      </div>
      <div className=" pb-4 mb-4">
        <p className="mb-0">
          Envie d'aller plus loin ? Aidez Houng Huan Yun pendant sa période de
          quarantaine au Lazaret à travers une histoire immersive.
        </p>
        <Button
          className="mt-3"
          color="primary"
          href="/"
          icon={<BsArrowRight />}
          text="Découvrir le jeu de rôle"
        />
      </div>
      <p>
        Saviez-vous que le Lazaret de La Grande Chaloupe possède une page
        Facebook et Instagram ? Nous y postons l'actualité et les évènements à venir, c'est
        par ici !
      </p>
      <div className="d-flex justify-content-center gap-5">
        <a
          href="https://www.facebook.com/lazaretdelagrandechaloupe"
          className="d-center border-0 fs-1"
          target="_BLANK"
          rel="noreferrer"
        >
          <BsFacebook size={40} className="text-primary" />
        </a>
        <a
          href="https://www.instagram.com/lazaretdelagrandechaloupe/"
          className="d-center border-0 fs-1"
          target="_BLANK"
          rel="noreferrer"
        >
          <AiFillInstagram size={50} className="text-primary" />
        </a>
      </div>
      <p className="text-center fs-1 title mt-4">
        <strong>À très bientôt !</strong>
      </p>
      <Button
        className="text-primary"
        href="/"
        text="Revenir à l'accueil"
        iconSlot="before"
        icon={<AiOutlineHome className="pb-1 fs-4" />}
      />
    </Page>
  );
};

export default VisitEndPage;
