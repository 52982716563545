import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Page from "../../../../Common/Page/Page";
import MuteSoundButton from "../MuteSoundButton";

const GameEnd = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(`/jeu-de-role/score`);
    }, 8000);
  }, []);

  return (
    <Page title={"  "}>
      <StyledContainer className="d-center">
        <div className="title animate__animated animate__fadeIn animate__slower">
          De par son rôle le Lazaret fait partie de l'histoire des engagés et de
          ce fait du métissage de La Réunion.
        </div>
      </StyledContainer>
    </Page>
  );
};

const bgAnim = keyframes`
0% {
  background-position:0%
}
100% {
  background-position:100%;
}
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background: url(/assets/images/game/steps/step8.webp);
  background-repeat: no-repeat;
  padding: 30px;
  animation-name: ${bgAnim};
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  & .title {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #763c14;
    background: rgba(245, 216, 177, 0.77);
    font-size: 30px;
    line-height: 38px;
    font-weight: 800;
    top: 0px;
    padding: 10px;
  }
`;

export default GameEnd;
