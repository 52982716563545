import Page from "../../Common/Page/Page";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { nbStepsAtom } from "../../hooks/atoms/atoms";

const IndexPage = () => {
  const [nbSteps] = useAtom(nbStepsAtom);

  return (
    <Page title="" contentContainerClassName="px-4">
      <h1 className="w-100 mb-0 title cursive text-center big">Lazaret</h1>
      <p className="text-center fs-5 mb-4">de La Grande Chaloupe</p>
      <PanelButton
        to="/visite-guidee"
        className="btn border-0 w-100 rounded d-center flex-column   shadow-lg btn-primary "
      >
        <div className="fs-1 mb-2">Visite guidée</div>
        <p className="mb-2">
          Découvrez le Lazaret un pas après l'autre, à travers {nbSteps} étapes
          riches en histoire et en couleurs.
        </p>
        <BsArrowRight />
      </PanelButton>
      <PanelButton
        to="/jeu-de-role"
        className="btn border-0 w-100 rounded d-center flex-column   shadow-lg btn-primary "
      >
        <div className="fs-1 mb-2">Jeu de rôle</div>
        <p className="mb-2">
          Accompagnez Houng Huan Yun, jeune engagé chinois, dans son
          débarquement au Lazaret.
        </p>
        <BsArrowRight />
      </PanelButton>
    </Page>
  );
};

const PanelButton = styled(Link)`
  min-height: 200px;
  margin-bottom: 1rem;
  & svg {
    font-size: 40px;
  }
`;

export default IndexPage;
