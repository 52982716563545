import { BsArrowRight } from "react-icons/bs";
import Button from "../../../Common/Button/Button";
import useVisitStepList from "./useVisitStepList";

const VisitStepList = () => {
  const { steps, visitedSteps, currentStep } = useVisitStepList();

  return (
    <div>
      {steps.map((step, stepIndex) => {
        const isCurrent = currentStep.id == step.id;
        const isVisited = visitedSteps.includes(step.id);
        return (
          <div className="mb-2" key={`step${step.id}`}>
            <Button
              color={`${isVisited ? "secondary text-primary" : "primary"}`}
              fullWidth
              text={`#${stepIndex + 1} ${step.title}`}
              icon={<BsArrowRight />}
              href={`/visite-guidee/etape/${step.slugName}`}
              className="d-flex justify-content-between py-3"
              style={{ fontSize: 18 }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default VisitStepList;
