import ReactAudioPlayer from "react-audio-player";
import { AUDIO_URL } from "../../config";
import useAudioPlayers from "./useAudioPlayers";

const AudioPlayers = () => {
  const { setPlayer1Ref, setPlayer2Ref, setPlayer3Ref, isSoundMuted } = useAudioPlayers();

  return (
    <>
      <ReactAudioPlayer
        ref={setPlayer1Ref}
        muted={isSoundMuted}
        volume={0.3}
        src={`${AUDIO_URL}win.mp3`}
      />
      <ReactAudioPlayer
        ref={setPlayer2Ref}
        muted={isSoundMuted}
        volume={0.3}
        src={`${AUDIO_URL}lose.mp3`}
      />
      {/* <ReactAudioPlayer
        ref={setPlayer3Ref}
        muted={isSoundMuted}
        volume={0.3}
        src={`${AUDIO_URL}end.mp3`}
      /> */}
    </>
  );
};

export default AudioPlayers;
