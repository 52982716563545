import useQuizzResults from "./useQuizzResults";
import { GiDiamondTrophy } from "react-icons/gi";
import { FaMedal, FaFlagCheckered } from "react-icons/fa";
import { GiMountainClimbing } from "react-icons/gi";
import RewardBadge from "./RewardBadge";

const QuizzResultsAppreciation = ({ animationDelay = 0 }) => {
  const { hasAllGood, nbGoodAnswers, nbWrongAnswers, hasAnsweredAll } = useQuizzResults();

  let config = {};
  if (hasAllGood) {
    config = {
      icon: <GiDiamondTrophy />,
      color: "success",
      rewardName: "Trophée de l'aventurier",
      text: "Un parcours sans faute !",
    };
  } else {
    if (nbGoodAnswers > nbWrongAnswers) {
      config = {
        icon: <FaMedal />,
        color: "success",
        rewardName: "Médaille de la distinction",
        text: "Une majorité de bonnes réponses !",
      };
    } else {
      config = {
        icon: <GiMountainClimbing />,
        color: "primary",
        rewardName: "Badge de scout",
        text: "Un début prometteur !",
      };
    }
  }

  return (
    <>
      <RewardBadge animationDelay={animationDelay} {...config} />
      {hasAnsweredAll && (
        <RewardBadge
          animationDelay={animationDelay + 1.5}
          color="success"
          rewardName={"Complétion parfaite"}
          text="A répondu à tous les Quizz !"
          icon={<FaFlagCheckered />}
        />
      )}
    </>
  );
};

export default QuizzResultsAppreciation;
