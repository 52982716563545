import React from "react";

const ControlInfo = ({ text }) => {
  return (
    <div className="text-grey mb-1" style={{ lineHeight: "20px" }}>
      <small>
        <i>{text}</i>
      </small>
    </div>
  );
};

export default React.memo(ControlInfo);
