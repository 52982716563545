import styled from "styled-components";
import useImageModal from "../../hooks/useImageModal";
import Fab from "../Fab/Fab";
import CloseButton from "./CloseButton";
import paperTexture from "../../theme/assets/images/paper-texture.png";

const ImageModal = () => {
  const { isModalOpen, modalTitle, modalContent, onModalDismiss, closeModal } = useImageModal();

  return (
    <StyledImageModal className="bg-secondary" isOpen={isModalOpen}>
      {modalContent}
      <Fab style={{ bottom: 66 }}>
        <CloseButton />
      </Fab>
    </StyledImageModal>
  );
};

const StyledImageModal = styled.div`
  background-image: url(${paperTexture});
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 999;
  overflow: hidden;
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "100vw")});
  transition: all 400ms;
`;

export default ImageModal;
