import { useAtom } from "jotai";
import React, { useState } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import AnswerResultPopup from "../../../Common/AnswerResultPopup/AnswerResultPopup";
import { CheckIcon } from "../../../Common/Button/Button";
import QuestionSeparator from "../../../Common/QuestionSeparator/QuestionSeparator";
import { quizzAnswersAtom, quizzPointsAtom } from "../../../hooks/atoms/atoms";

const QuizzBlock = ({ data }) => {
  const [quizzAnswers, setQuizzAnswers] = useAtom(quizzAnswersAtom);
  const [answer, setAnswer] = useState(quizzAnswers[data.id]);
  const [quizzPoints, setQuizzPoints] = useAtom(quizzPointsAtom);

  const handleChangeAnswer = (value) => {
    if (!answer) {
      if (value == data.goodAnswer) {
        setQuizzPoints(quizzPoints + 1);
      }
      setAnswer(value);
      setQuizzAnswers({ ...quizzAnswers, [data.id]: value });
    }
  };

  const getButtonAspect = (btnNum) => {
    if (answer) {
      if (btnNum == data.goodAnswer) {
        return "btn-success animate__animated animate__bounce";
      } else {
        if (btnNum == answer) {
          return "btn-danger text-white animate__animated animate__headShake";
        }
      }
    }
    return "btn-secondary text-primary";
  };

  return (
    <div className="mt-4">
      <div className="text-center mb-4 fs-4 title ">
        <strong>QUIZ</strong>
      </div>
      <QuestionSeparator />
      <StyledQuestion className="fw-bold mb-3 pt-4 fs-5">{data.question}</StyledQuestion>
      <ButtonsContainer className="d-flex flex-column text-white btn-group-vertical">
        {Array(3)
          .fill(1)
          .map((i, idx) => {
            let btnNum = idx + 1;
            return (
              <button
                className={`btn py-2 position-relative ${getButtonAspect(btnNum)}`}
                onClick={() => handleChangeAnswer(btnNum)}
                checked={btnNum == answer}
                key={`ansbtn-${btnNum}`}
              >
                {data[`answer${btnNum}`]}
                {btnNum == answer && <CheckIcon />}
              </button>
            );
          })}
      </ButtonsContainer>

      <div className="d-center py-2">
        {answer && <AnswerResultPopup isAnswerValid={answer == data.goodAnswer} />}
      </div>
    </div>
  );
};

const StyledQuestion = styled.div`
  color: var(--pink-color);
`;

const ButtonsContainer = styled.div`
  & .btn {
    min-height: 48px;
  }
`;

export default QuizzBlock;
