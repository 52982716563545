import { useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { BsArrowRight, BsFacebook, BsInstagram } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import Button from "../../../../Common/Button/Button";
import Page from "../../../../Common/Page/Page";
import useGame from "../../useGame";
import ScoreBox from "./ScoreBox";

const GameScore = () => {
  const { results } = useGame();

  const totalQuestions =
    parseInt(results.goodAnswers) + parseInt(results.wrongAnswers);

  return (
    <Page title="Fin de l'aventure" contentContainerClassName="px-4">
      <div>
        <p>
          C'est ici que se termine l'aventure de notre jeune Houng Huan Yun.
          Nous esperons que vous l'avez aimé !
        </p>
      </div>
      <div className="d-center">
        <ScoreBox
          score={results.score}
          total={80}
          tint="brown"
          text="Votre score"
          size={240}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <ScoreBox
          score={results.goodAnswers}
          total={totalQuestions}
          tint="green"
          text="Bonnes réponses"
          size={120}
        />
        <ScoreBox
          score={results.wrongAnswers}
          total={totalQuestions}
          tint="red"
          text="Mauvaises réponses"
          size={120}
        />
      </div>
      <div className="mt-3 py-4 mb-4">
        <p className="mb-0">
          Si ce n'est pas encore fait, nous vous invitons à découvrir notre
          visite guidée, afin d'en apprendre encore plus sur ce lieu chargé
          d'histoire.
        </p>
        <Button
          className="mt-3"
          color="primary"
          href="/"
          icon={<BsArrowRight />}
          text="Découvrir la visite guidée"
        />
      </div>
      <p>
        Saviez-vous que le Lazaret de La Grande Chaloupe possède une page
        Facebook et Instagram ? Nous y postons l'actualité et les évènements à venir, c'est
        par ici !
      </p>
      <div className="d-flex justify-content-center gap-5">
        <a
          href="https://www.facebook.com/lazaretdelagrandechaloupe"
          className="d-center border-0 fs-1"
          target="_BLANK"
        >
          <BsFacebook size={40} className="text-primary" />
        </a>
        <a
          href="https://www.instagram.com/lazaretdelagrandechaloupe/"
          className="d-center border-0 fs-1"
          target="_BLANK"
        >
          <AiFillInstagram size={50} className="text-primary" />
        </a>
      </div>
      <p className="text-center fs-1 title mt-4">
        <strong>À très bientôt !</strong>
      </p>
      <Button
        className="text-primary"
        href="/"
        text="Revenir à l'accueil"
        iconSlot="before"
        icon={<AiOutlineHome className="pb-1 fs-4" />}
      />
    </Page>
  );
};

export default GameScore;
