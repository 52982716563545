import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./AppRoutes";
import ContentWrapper from "./Common/ContentWrapper/ContentWrapper";
import useApp from "./useApp";
import LoadScreen from "./Common/LoadScreen/LoadScreen";
import CustomModal from "./Common/CustomModal/CustomModal";
import ImageModal from "./Common/ImageModal/ImageModal";
import AudioPlayers from "./Common/AudioPlayers/AudioPlayers";

function App() {
  const { isLoadingApiDatas } = useApp();

  return isLoadingApiDatas ? (
    <LoadScreen />
  ) : (
    <StyledAppWrapper>
      <BrowserRouter>
        <ContentWrapper>
          <AppRoutes />
        </ContentWrapper>
      </BrowserRouter>
      <ToastContainer />
      <CustomModal />
      <ImageModal />
      <AudioPlayers />
    </StyledAppWrapper>
  );
}

const StyledAppWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;

export default App;
