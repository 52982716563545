import { BiCameraMovie } from "react-icons/bi";
import MemoedVideoPlayer from "../../../Common/VideoPlayer/VideoPlayer";

const VideoBlock = ({ data }) => {
  return (
    <div className="w-100 rounded overflow-hidden shadow bg-secondary bg-opacity-50">
      <MemoedVideoPlayer videoId={data.videoId} />
      <div className="px-3 py-2 ">
        <div className="d-flex w-100 justify-content-start align-items-center pb-2">
          <BiCameraMovie style={{ fontSize: 18 }} />
          <strong className="fs-5 ps-2">{data.title}</strong>
        </div>
        <p className="mb-0">{data.description}</p>
      </div>
    </div>
  );
};

export default VideoBlock;
