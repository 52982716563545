import { useAtom } from "jotai";
import { useEffect } from "react";
import { askedUserPositionAtom, isMapOpenAtom, userPositionAtom } from "../../atoms/mapAtoms";

const useUserPosition = () => {
  const [userPosition, setUserPosition] = useAtom(userPositionAtom);
  const [isMapOpen] = useAtom(isMapOpenAtom);
  const [askedUserPosition, setAskedUserPosition] = useAtom(askedUserPositionAtom);

  const onWantToLocateUser = () => {
    if (!askedUserPosition) {
      askUserPosition();
    }
  };

  const askUserPosition = () => {
    setAskedUserPosition(true);
    alert(
      "Pour afficher votre position sur la carte, veuillez autoriser la page à accéder à votre position."
    );
  };

  useEffect(() => {
    if (isMapOpen) {
      if ("geolocation" in navigator) {
        if (!askedUserPosition) {
          askUserPosition();
        }
        navigator.geolocation.watchPosition(
          function (position) {
            setUserPosition([position.coords.longitude, position.coords.latitude]);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  }, [isMapOpen]);

  return {
    userPosition,
    askUserPosition,
    onWantToLocateUser,
  };
};

export default useUserPosition;
