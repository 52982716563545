import styled from "styled-components";

const AnecdoteBlock = ({ data }) => {
  return (
    <StyledP className="mb-0 fs-4 text-center">
      <i>{data.text}</i>
    </StyledP>
  );
};

const StyledP = styled.p`
  color: var(--pink-color);
  margin-bottom: 0px;
`;

export default AnecdoteBlock;
