import { BsArrowRight } from "react-icons/bs";
import Button from "../../../../Common/Button/Button";
import QuestionSeparator from "../../../../Common/QuestionSeparator/QuestionSeparator";
import ButtonList from "../../Controls/ButtonList/ButtonList";
import CheckList from "../../Controls/CheckList/CheckList";
import TextHole from "../../Controls/TextHole/TextHole";
import useGameStep from "../useGameStep";
import AnswerResult from "./AnswerResult";
import NbTries from "./NbTries";
import Question from "./Question";
import ValidButton from "./ValidButton";
import { motion } from "framer-motion";
import Confetti from "../../../../Common/Confetti/Confetti";
import CheckImage from "../../Controls/CheckImage/CheckImage";

const componentTypes = {
  ButtonList: ButtonList,
  CheckList: CheckList,
  TextHole: TextHole,
  CheckImage: CheckImage,
};

const ChallengeBlock = () => {
  const {
    stepsState,
    currentStepStateRef,
    currentStepRef,
    handleAnswerChange,
    nextStepLink,
    viewConditions,
  } = useGameStep();

  const AnswerComponent = componentTypes[currentStepRef.component];

  return (
    <div
      className="position-relative"
      style={{
        padding: 20,
        background: "rgb(255 255 255 / 85%)",
        borderRadius: 10,
        margin: "0px 10px 20px",
      }}
    >
      <div className="row mx-0  border-primary  my-3 position-relative" style={{ zIndex: 8 }}>
        <QuestionSeparator />
        <motion.div
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: 40, opacity: 0 }}
          exit={{
            opacity: 0,
            y: 40,
          }}
          transition={{
            duration: 0.4,
            delay: 0.2,
          }}
          className="col-12 mt-3"
        >
          <Question question={currentStepRef.question} />
        </motion.div>
        <motion.div
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: 40, opacity: 0 }}
          exit={{
            opacity: 0,
            y: 40,
          }}
          transition={{
            duration: 0.4,
            delay: 0.4,
          }}
          className="col-12 my-3"
        >
          <AnswerComponent
            name={`${currentStepRef.id}`}
            value={currentStepStateRef.answer}
            onChange={handleAnswerChange}
            disabled={viewConditions.isInputDisabled}
            showErrors={currentStepStateRef.isValidated || viewConditions.canGoToNextStep}
            goodAnswer={currentStepRef.goodAnswer}
            {...currentStepRef.params}
          />
        </motion.div>
        {viewConditions.canSeeAnswerResult && (
          <div className="col-12 d-center ">
            <AnswerResult isGoodAnswer={currentStepStateRef.isGoodAnswer} />
          </div>
        )}
        {viewConditions.canSeeNbTries && (
          <motion.div
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 40, opacity: 0 }}
            exit={{
              opacity: 0,
              y: 40,
            }}
            transition={{
              duration: 0.4,
              delay: 0.8,
            }}
            className="col-12"
          >
            <NbTries />
          </motion.div>
        )}
        {viewConditions.canValidateAnswer && (
          <div className="col-12 my-3">
            <div className="d-center flex-column  animate__animated animate__fadeIn animate__slow">
              <ValidButton />
            </div>
          </div>
        )}

        {viewConditions.canGoToNextStep && (
          <div className="col-12 mt-3">
            <Button
              className="btn-lg"
              color="primary"
              href={nextStepLink}
              text="Étape suivante"
              icon={<BsArrowRight />}
            />
          </div>
        )}
      </div>
      {/* {currentStepStateRef.isGoodAnswer && <Confetti />} */}
    </div>
  );
};

export default ChallengeBlock;
