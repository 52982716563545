import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../../../Common/Page/Page";
import ChallengeBlock from "./ChallengeBlock/ChallengeBlock";
//import ConfigEditor from "./ConfigEditor";
import MuteSoundButton from "./MuteSoundButton";
import StepCharacters from "./StepCharacters";
import StepContainer from "./StepContainer";
import StepDialogs from "./StepDialogs/StepDialogs";
import useGameStep from "./useGameStep";
import { AiTwotoneHome } from "react-icons/ai";

const GameStep = () => {
  const {
    currentStepRef,
    prevStepLink,
    viewConditions,
    currentStepStateRef,
    gameSteps,
    onSetCurrentStep,
    setCurrentDialog,
    slugName,
    currentDialog,
  } = useGameStep();

  const navigate = useNavigate();

  useEffect(() => {
    //Si on essaie de naviguer directement sur une étape qui n'est pas unlocked, on redirige vers l'accueil
    const stepFromSlugName = gameSteps.find(
      (step) => step.slugName == slugName
    );
    if (
      !currentStepStateRef ||
      !currentStepStateRef.isUnlocked ||
      !stepFromSlugName
    ) {
      onSetCurrentStep(1);
      navigate("/jeu-de-role");
    } else {
      onSetCurrentStep(stepFromSlugName.id);
      setCurrentDialog(0);
    }
  }, []);

  return (
    <Page
      title={"  "}
      back={"/"}
      backIcon={<AiTwotoneHome />}
      end={<MuteSoundButton />}
    >
      <div className="px-4">
        <StepContainer>
          <StepCharacters />
          <div style={{ position: "relative", zIndex: "999" }}>
            {viewConditions.isLastDialog && <ChallengeBlock />}
            {!viewConditions.isLastDialog && <StepDialogs />}
          </div>
        </StepContainer>
      </div>
      {/* <ConfigEditor /> */}
    </Page>
  );
};

export default GameStep;
