import { useAtom } from "jotai";
import { activeMarkerAtom } from "../../../atoms/mapAtoms";
import useApi from "../../../hooks/useApi";

const useMarkers = () => {
  const { apiConstants } = useApi();
  const { STEP_POSITIONS } = apiConstants;
  const markers = STEP_POSITIONS;
  const [activeMarker, setActiveMarker] = useAtom(activeMarkerAtom);

  const getIdFromName = (markerName) => {
    const markerRef = markers.find((m) => m.title.toLowerCase() == markerName.toLowerCase());

    return markerRef?.id || 1;
  };

  const onChangeActiveMarker = (markerId) => {
    setActiveMarker(markerId);
  };

  return {
    activeMarker,
    markers,
    onChangeActiveMarker,
    getIdFromName,
  };
};

export default useMarkers;
