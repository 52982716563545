import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const apiDatasAtom = atomWithStorage("LAZARET_DATA", null);
export const orderedPositionsAtom = atom((get) => {
  const steps = get(apiDatasAtom);
  let positions = [];
  if (steps) {
    steps.forEach((step) => {
      if (step.position?.id) {
        if (!positions.find((pos) => pos.id == step.position.id)) {
          positions.push(step.position);
        }
      }
    });
  }
  return positions;
});
export const apiConstantsAtom = atomWithStorage("LAZARET_CONSTANT", null);
export const isLoadingApiDatasAtom = atom(true);
export const visitedStepsAtom = atomWithStorage("LAZARET_VISITED_STEPS", []);
export const currentStepAtom = atom("");
export const currentStepIndexAtom = atom((get) => {
  let steps = get(apiDatasAtom);
  let currentStep = get(currentStepAtom);
  if (steps && steps.length > 0 && currentStep) {
    let currentStepIndex = steps.findIndex((step) => step.id == currentStep.id);

    return currentStepIndex;
  }
});
export const nbStepsAtom = atom((get) => {
  let steps = get(apiDatasAtom);
  return steps?.length || 0;
});
export const prevStepAtom = atom((get) => {
  let steps = get(apiDatasAtom);
  let currentStepIndex = get(currentStepIndexAtom);
  let currentStep = get(currentStepAtom);
  if (steps && steps.length > 0 && currentStep) {
    return steps[currentStepIndex - 1] || null;
  }
  return null;
});
export const nextStepAtom = atom((get) => {
  let steps = get(apiDatasAtom);
  let currentStepIndex = get(currentStepIndexAtom);
  let currentStep = get(currentStepAtom);
  if (steps && steps.length > 0 && currentStep) {
    return steps[currentStepIndex + 1] || null;
  }
  return null;
});

export const quizzAnswersAtom = atomWithStorage("LAZARET_QUIZZ", {});
export const quizzPointsAtom = atomWithStorage("LAZARET_QUIZZ_POINTS", 0);
export const nbQuizzBlocksAtom = atom((get) => {
  const steps = get(apiDatasAtom);
  let nbQuizz = 0;
  if (steps) {
    steps.forEach((step) => {
      step.stepBlocks.forEach((block) => {
        if (block.type == "QUIZZ") {
          nbQuizz++;
        }
      });
    });
  }
  return nbQuizz;
});
