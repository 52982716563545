import { useAtom } from "jotai";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import Button from "../../../Common/Button/Button";
import { nextStepAtom, prevStepAtom } from "../../../hooks/atoms/atoms";

const StepNavigation = () => {
  const [prevStep] = useAtom(prevStepAtom);
  const [nextStep] = useAtom(nextStepAtom);

  return (
    <StyledStepNavigation className="shadow border-top bg-opacity-75 bg-secondary border-primary">
      <div className="d-flex justify-content-between align-items-center h-100">
        <div className="btn-container w-25">
          {prevStep && (
            <Button
              color=""
              href={`/visite-guidee/etape/${prevStep.slugName}`}
              text={
                <div className="btn-content btn-prev w-100 py-2">
                  {/* <div className="btn-title">{prevStep.title}</div> */}
                  <BsArrowLeft />
                </div>
              }
              iconSlot="before"
            />
          )}
        </div>
        <div className="btn-container w-75">
          <Button
            color=""
            href={nextStep ? `/visite-guidee/etape/${nextStep.slugName}` : `/visite-guidee/fin`}
            text={
              <div className="btn-content btn-next w-100 py-2">
                <div className="btn-title">
                  {nextStep?.title || "Fin de la visite"}

                  <BsArrowRight className="ps-2 btn-content-icon" />
                </div>
              </div>
            }
          />
        </div>
      </div>
    </StyledStepNavigation>
  );
};

const StyledStepNavigation = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: min(100%, 500px);
  backdrop-filter: blur(5px);
  z-index: 9;

  & .btn-container {
    & .btn-content {
      color: var(--bs-primary);
      &.btn-prev {
        text-align: left;
      }
      &.btn-next {
        text-align: right;
      }
      & .btn-title {
        font-size: 18px;
        & .btn-content-icon {
          font-size: 28px;
        }

        & .step-progress {
          font-size: 11px;
        }
      }
    }
  }
`;

export default StepNavigation;
