import { atom, useAtom } from "jotai";

const player1RefAtom = atom(null);
const player2RefAtom = atom(null);
const player3RefAtom = atom(null);
const isSoundMutedAtom = atom(false);

const useAudioPlayers = () => {
  const [player1Ref, setPlayer1Ref] = useAtom(player1RefAtom);
  const [player2Ref, setPlayer2Ref] = useAtom(player2RefAtom);
  const [player3Ref, setPlayer3Ref] = useAtom(player3RefAtom);
  const [isSoundMuted, setIsSoundMuted] = useAtom(isSoundMutedAtom);

  const playSound = (type) => {
    switch (type) {
      case "WIN":
        console.log("play win.mp3");
        player1Ref?.audioEl?.current?.play();
        break;
      case "LOSE":
        console.log("play lose.mp3");

        player2Ref?.audioEl?.current?.play();
        break;
      case "END":
        console.log("play end.mp3");

        player3Ref?.audioEl?.current?.play();
        break;
    }
  };

  const toggleSound = () => {
    setIsSoundMuted(!isSoundMuted);
  };

  return {
    isSoundMuted,
    setIsSoundMuted,
    player1Ref,
    setPlayer1Ref,
    player2Ref,
    setPlayer2Ref,
    player3Ref,
    setPlayer3Ref,
    playSound,
    toggleSound,
  };
};

export default useAudioPlayers;
