import { useAtom } from "jotai";
import { currentStepAtom, visitedStepsAtom } from "../../../hooks/atoms/atoms";
import useApi from "../../../hooks/useApi";

const useVisitStepList = () => {
  const { apiDatas, apiConstants } = useApi();
  const [visitedSteps, setVisitedSteps] = useAtom(visitedStepsAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);

  const onSetStepVisited = (stepId) => {
    setVisitedSteps([...visitedSteps, stepId]);
  };

  const onSetCurrentStep = (step) => {
    setCurrentStep(step);
  };

  const getLastVisitedStep = () => {
    let lastVisitedStep = null;
    apiDatas.forEach((step) => {
      if (visitedSteps.includes(step.id)) {
        lastVisitedStep = step;
      }
    });
    return lastVisitedStep;
  };

  console.log("apiDatas", apiDatas);
  console.log("apiConstants", apiConstants);

  return {
    steps: apiDatas,
    constants: apiConstants,
    visitedSteps,
    currentStep,
    getLastVisitedStep,
    onSetCurrentStep,
    onSetStepVisited,
  };
};

export default useVisitStepList;
