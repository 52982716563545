import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import useImageModal from "../../hooks/useImageModal";
import Button from "../Button/Button";

const CloseButton = () => {
  const { closeModal } = useImageModal();

  return (
    <CloseBtn
      color="white"
      className="bg-grey text-white"
      onClick={closeModal}
      icon={<BsArrowRight />}
    />
  );
};

const CloseBtn = styled(Button)``;

export default CloseButton;
