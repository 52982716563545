import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import Block from "./StepBlocks/Block";
import StepNavigation from "./StepNavigation/StepNavigation";
import useStepPage from "./useStepPage";
import { AiOutlineHome } from "react-icons/ai";
import OpenMapButton from "../../Common/Map/OpenMapButton/OpenMapButton";

const StepPage = () => {
  const { currentStep, stepNum, nbSteps } = useStepPage();

  if (!currentStep) {
    return (
      <div className="w-100 h-100 d-center">
        <Loader />
      </div>
    );
  }

  console.log(currentStep);

  return (
    <Page
      back="/visite-guidee"
      title={currentStep.title}
      titleCN="mt-4"
      backIcon={<AiOutlineHome />}
      end={
        <OpenMapButton
          className="p-0  h-100 text-primary  justify-content-end ps-2"
          text={<span className="pt-1">{currentStep.position.title}</span>}
        />
      }
    >
      <div className="pb-5">
        {currentStep.stepBlocks.map((block) => (
          <Block blockData={block} key={`block${block.id}`} />
        ))}
        <div className="d-flex w-100 px-4 justify-content-end">
          <small className="step-progress ms-2">
            {stepNum}/{nbSteps}
          </small>
        </div>
      </div>
      <StepNavigation />
    </Page>
  );
};

export default StepPage;
