export const ANIM_TYPES = {
  PLAY_ANIMATION: "PLAY_ANIMATION",
  REPLACE_IMAGE: "REPLACE_IMAGE",
};

export const step1Config = [
  {
    text: "Tu incarnes Houng Huan Yun, un jeune chinois âgé de 34 ans, venu de la province de Fujin au sud de la Chine. On est venu t'engager pour venir travailler à la coupe de canne à sucre, sur la petite île de La Réunion. ",
  },
  {
    text: "Tu embarques sur le navire Erica, pour plusieurs semaines de navigation. Au total, vous serez 801 chinois à bord. On vous appelle les Engagés.",
  },
  {
    text: "Le 19 Octobre 1901, vous arrivez enfin à La Réunion. Vous débarquez au débarcadère de La Grande Chaloupe, une petite vallée entre St Denis et La Possession.",
  },
];
export const step2Config = [
  {
    speaker: "Médecin",
    text: "Bonjour à tous, bienvenue à  l'île de La Réunion. Je suis le médecin en chef du Lazaret de La Grande Chaloupe.",
    imageAnimations: [
      {
        id: "medecin_neutre",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeInLeft",
        style: {
          maxWidth: "40%",
          left: "5%",
          bottom: "20%",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Après le débarquement, nous avons été accueillis par un médecin ainsi qu'un traducteur...",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "zoomIn",
        style: {
          maxWidth: "15%",
          right: "12%",
          bottom: "35%",
        },
      },
    ],
  },
  {
    speaker: "Médecin",
    text: "Toute personne extérieure à l'île doit subir une quarantaine de 10 jours ;\n-Vous allez donc nous suivre jusqu'au Lazaret  afin d'être examinés et comptabilisés.",
  },
];
export const step3Config = [
  {
    speaker: "Houng Huan Yun",
    text: "Lors de l'examen, le médecin me pose des questions : \nquel âge as-tu ? \nde quelle région viens-tu ?",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeIn",
        style: {
          maxWidth: "40%",
          right: "10%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Il note également des détails ou des signes distincitfs comme les signes de mon visage  ainsi que ma taille. ",
  },
  {
    speaker: "Houng Huan Yun",
    text: "C'est le deuxième document important avec le contrat de travail si je veux travailler sur l'ile !",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Après l'examen, on nous emmène au dortoir.",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Sur le chemin, il y a beaucoup de gens d'origines différentes : Indiens, Africains, Malgaches...\nC'est la première fois que je vois autant de peuples différents au même endroit.",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
        replaceWithImageId: "hhy_joyeux",
        style: {
          maxWidth: "40%",
          right: "10%",
          bottom: "10%",
          zIndex: "2",
        },
      },
      {
        id: "foule",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeIn",
        style: {
          maxWidth: "100%",
          right: "0%",
          bottom: "24%",
          zIndex: "1",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Une scène attire mon attention : \nUne indienne assez âgée avec des plantes dans les mains vient de tomber par terre non loin du dortoir. Un officier/ traducteur semble l'avoir bousculé volontairement. Je ne comprends pas ce qu'il se passe. ",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
        replaceWithImageId: "hhy_neutre",
        style: {
          maxWidth: "40%",
          right: "10%",
          bottom: "10%",
          zIndex: "2",
        },
      },
      {
        id: "foule",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeOut",
        style: {
          maxWidth: "100%",
          right: "0%",
          bottom: "24%",
          zIndex: "1",
        },
      },
      {
        id: "prya_neutre",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeInLeft",
        style: {
          maxWidth: "35%",
          left: "5%",
          bottom: "10%",
          zIndex: "2",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Cette attitude me met en colère. Chez nous le respect des anciens est important. Face à ce comportement irrespectueux, je sors du groupe et je me précipite pour aider la femme à se relever.",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
        replaceWithImageId: "hhy_colere",
        style: {
          maxWidth: "40%",
          right: "10%",
          bottom: "10%",
          zIndex: "2",
        },
      },

      {
        id: "prya_neutre",
        replaceWithImageId: "prya_triste",
        type: ANIM_TYPES.REPLACE_IMAGE,
        style: {
          maxWidth: "35%",
          left: "5%",
          bottom: "10%",
          zIndex: "2",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "-Ça va ? Vous n'avez rien ?\n Elle ne répond pas et baisse la tête.",
    imageAnimations: [
      {
        id: "hhy_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
        replaceWithImageId: "hhy_neutre",
        style: {
          maxWidth: "40%",
          right: "25%",
          bottom: "10%",
          zIndex: "2",
        },
      },
    ],
  },
];
export const step4Config = [
  {
    text: "Depuis son arrivée à La Réunion, Houng Huan Yun n'est pas au meilleur de sa forme. Son ventre lui fait régulièrement mal et de plus en plus violement.",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Aujourd'hui, Les tâches quotidiennes vont être compliquées pour moi... Mon ventre me fait vraiment souffrir... J'ai peur d'aller voir le médecin...",
    imageAnimations: [
      {
        id: "hhy_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "flipInY slower",
        style: {
          maxWidth: "40%",
          left: "45%",
          bottom: "10%",
        },
      },
    ],
  },
];
export const step5Config = [
  {
    speaker: "Houng Huan Yun",
    text: "Je ne veux pas que le médecin prolonge ma quarantaine. On a besoin d'argent pour vivre et on n'est payé qu'après avoir travaillé aux champs.",
    imageAnimations: [
      {
        id: "hhy_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeIn slow",
        style: {
          maxWidth: "40%",
          right: "5%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    text: "Houng Huan Yun a vraiment mal au ventre, il est pris d'une vive douleur lors d'une de ses tâches quotidiennes.",
  },
  {
    speaker: "Houng Huan Yun",
    text: "(douleur)",
    imageAnimations: [
      {
        id: "hhy_triste",
        replaceWithImageId: "hhy_douleur",
        animation: "shakeX",
        type: ANIM_TYPES.REPLACE_IMAGE,
        style: {
          maxWidth: "27%",
          right: "10%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    text: "Houng Huan Yun s'écroule de douleur dans le jardin derrière le dortoir. Prya qui passe non loin de là, le voit et s'approche pour voir ce qu'il a.",
  },
  {
    speaker: "Prya",
    text: "...",
    imageAnimations: [
      {
        id: "prya_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeInLeft slower",
        style: {
          maxWidth: "20%",
          right: "50%",
          bottom: "17%",
          zIndex: "1",
        },
      },
      {
        id: "hhy_douleur",
        replaceWithImageId: "hhy_triste",
        type: ANIM_TYPES.REPLACE_IMAGE,
        style: {
          maxWidth: "40%",
          right: "5%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    text: "Elle lui fait un signe d'attendre caché dans le jardin.",
    imageAnimations: [
      {
        id: "prya_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeOutLeft",
      },
    ],
  },
  {
    speaker: "Prya",
    text: "Tiens, bois ! (en tamoul)",
    imageAnimations: [
      {
        id: "hhy_triste",
        replaceWithImageId: "hhy_douleur",
        type: ANIM_TYPES.REPLACE_IMAGE,
      },
      {
        id: "prya_neutre",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeInLeft",
        style: {
          maxWidth: "30%",
          right: "35%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Qu'est ce que c'est ?",
    imageAnimations: [
      {
        replaceWithImageId: "hhy_neutre",
        id: "hhy_douleur",
        type: ANIM_TYPES.REPLACE_IMAGE,
      },
    ],
  },
  {
    text: "La douleur est telle qu'il n'a rien à perdre et boit la tisane. Très peu de temps après, la douleur commence à se dissiper.",
  },
];
export const step6Config = [
  {
    speaker: "Houng Huan Yun",
    text: "Cela fait 10 jours que je suis au Lazaret. Je vais enfin pouvoir finir ma quarantaine.",
    imageAnimations: [
      {
        id: "hhy_joyeux",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "bounceInLeft",
        style: {
          maxWidth: "40%",
          left: "10%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    text: "Il est convenu que je rejoigne mon employeur, monsieur Kervéguen, sur une exploitation à Saint Louis.",
    imageAnimations: [
      {
        id: "hhy_joyeux",
        replaceWithImageId: "hhy_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
      },
    ],
  },
];
export const step7Config = [
  {
    text: "Lors de son arrivée chez le planteur Houng Huan Yun croise de nouveau Prya. Cela fait moins de deux mois que Houng Huan Yun est arrivé mais au fil du temps, ils ont tissé une forme d'amitié et ont appris à communiquer.",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Ce n'est plus possible on ne peut pas continuer à travailler dans de telles conditions...",
    imageAnimations: [
      {
        id: "hhy_colere",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeIn slow",
        style: {
          maxWidth: "40%",
          left: "10%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    speaker: "Prya",
    text: "Calme toi...",
    imageAnimations: [
      {
        id: "prya_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeIn",
        style: {
          maxWidth: "34%",
          right: "10%",
          bottom: "10%",
        },
      },
    ],
  },
  {
    speaker: "Houng Huan Yun",
    text: "Comment tu peux accepter d'être traitée comme une esclave, de n'avoir qu'une poignée de riz comme nourriture. On est traité comme des chiens !",
    imageAnimations: [
      {
        id: "prya_triste",
        replaceWithImageId: "prya_neutre",
        type: ANIM_TYPES.REPLACE_IMAGE,
      },
    ],
  },
  {
    speaker: "Prya",
    text: "...on ne peut pas faire changer les choses.",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Mais si ! tu vas voir !",
  },
  {
    speaker: "Houng Huan Yun",
    text: "Je quitte prya, toujours en colère, comme les 40 autres travailleurs chinois de l'exploitation.",
    imageAnimations: [
      {
        id: "prya_triste",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeOutRight",
      },
      {
        id: "hhy_colere",
        type: ANIM_TYPES.PLAY_ANIMATION,
        animation: "fadeOutLeft",
      },
    ],
  },
  {
    text: "Le 11 décembre 1901, 2 groupes de travailleurs chinois s'organisent pour protester : \n20 se portent malades avant le début du travail et les 20 autres à l'heure du repas protestent contre le manque de nourriture et abandonnent leurs tâches de l'après-midi.",
  },
];
export const step8Config = [
  {
    text: "L'engagisme a duré près d'un siècle et a marqué l'histoire de l'île.",
  },
  {
    text: "Malgré des conditions plus que difficiles certains engagés sont restés à La Réunion, peuplant l'île d'ethnies, de langues et de cultures différentes.",
  },
  {
    text: "Les révoltes issues de cette période ont façonné La Réunion d'aujourd'hui, et chaque peuple s'est spécialisé dans des domaines/métiers distincts.",
  },
];
