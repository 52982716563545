import React from "react";
import Button from "../../../../Common/Button/Button";
import styled from "styled-components";
import ControlInfo from "../ControlInfo/ControlInfo";

const ButtonList = ({ datas, value, onChange, name, showErrors, goodAnswer, ...props }) => {
  const handleChange = (id) => {
    onChange({
      target: {
        name: name,
        value: id,
      },
    });
  };

  const commonProps = {
    name: name,
    fullWidth: true,
    className: "mt-2 py-2",
  };
  const activeProps = {
    checked: true,
    color: "primary",
    ...commonProps,
  };
  const inactiveProps = {
    color: "secondary",
    ...commonProps,
  };

  return (
    <StyledButtonList>
      {!props.disabled && <ControlInfo text="Cochez une seule réponse" />}

      {datas.map((data) => {
        const btnProps = data.id == value ? activeProps : inactiveProps;

        return (
          <Button
            onClick={() => handleChange(data.id)}
            text={data.name}
            key={`btl${data.id}`}
            {...btnProps}
            {...props}
          />
        );
      })}
    </StyledButtonList>
  );
};

const StyledButtonList = styled.div`
  & button {
    min-height: 48px;
  }
  & button:last-child {
    margin-bottom: 0px;
  }
`;

export default ButtonList;
