import styled from "styled-components";
import { css } from "styled-components";

const RewardBadge = ({ color, icon, rewardName, text, animationDelay }) => {
  return (
    <StyledAppreciation className="d-center flex-column">
      <RewardIcon
        color={color}
        animationDelay={animationDelay}
        className={`icon d-center shadow  animate__animated animate__bounceInLeft reward__icon bg-gradient `}
      >
        <div className="animate__animated animate__pulse animate__infinite d-center">{icon}</div>
      </RewardIcon>
      <RewardName
        animationDelay={animationDelay}
        className={`shadow badge border bg-${color}  animate__animated animate__bounceInRight reward__name  `}
      >
        {rewardName}
      </RewardName>
      <RewardText
        animationDelay={animationDelay}
        className={` animate__animated animate__fadeInDown  reward__text `}
      >
        {text}
      </RewardText>
    </StyledAppreciation>
  );
};

const StyledAppreciation = styled.div``;
const RewardIcon = styled.div`
  font-size: 50px;
  padding: 20px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: ${({ color }) => css`var(--bs-${color})`};
  color: white;
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;
const RewardName = styled.div`
  position: relative;
  bottom: 30px;
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;

const RewardText = styled.i`
  position: relative;
  bottom: 17px;
  font-size: 12px;
  animation-delay: ${({ animationDelay }) => animationDelay + 1}s;
`;

export default RewardBadge;
