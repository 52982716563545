import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useMarkers from "../../Common/Map/Marker/useMarkers";
import { currentStepIndexAtom, nbStepsAtom } from "../../hooks/atoms/atoms";
import useVisitStepList from "../VisitPage/VisitStepList/useVisitStepList";

const useStepPage = () => {
  const { slugName } = useParams();
  const navigate = useNavigate();
  const { steps, currentStep, onSetCurrentStep, onSetStepVisited } = useVisitStepList();
  const { onChangeActiveMarker, getIdFromName } = useMarkers();
  const [currentStepIndex] = useAtom(currentStepIndexAtom);
  const [nbSteps] = useAtom(nbStepsAtom);

  useEffect(() => {
    const stepFromSlugName = steps.find((step) => step.slugName == slugName);
    if (!stepFromSlugName) {
      onSetCurrentStep("");
      navigate("/");
    } else {
      onSetCurrentStep(stepFromSlugName);
      onSetStepVisited(stepFromSlugName.id);
      onChangeActiveMarker(getIdFromName(stepFromSlugName.position.title));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep?.id]);

  return {
    currentStep,
    stepNum: currentStepIndex + 1,
    nbSteps,
  };
};

export default useStepPage;
