import Loader from "../Loader/Loader";
import { motion } from "framer-motion";

const LoadScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  return (
    <div
      initial="initial"
      exit="out"
      animate="in"
      variants={pageVariants}
      transition={{ duration: 0.3 }}
      className="d-center flex-column h-100 w-100 position-absolute top-0 start-0 animate__animated animate__zoomIn animate__fast"
    >
      <h1 className="w-100 text-center title cursive big mb-0">Lazaret</h1>
      <p className="fs-5">de La Grande Chaloupe</p>
      <Loader />
    </div>
  );
};

export default LoadScreen;
