import styled from "styled-components";
import useGameStep from "./useGameStep";

const StepContainer = ({ children }) => {
  const { currentStepRef } = useGameStep();

  return (
    <StyleStepContainer
      id="step-container"
      bgImage={`/assets/images/game/steps/step${currentStepRef.id}.webp`}
    >
      {children}
    </StyleStepContainer>
  );
};

const StyleStepContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-image: url(${({ bgImage }) => bgImage});
  display: flex;
  justify-content: end;
  flex-direction: column;
  background-size: cover;
  overflow: hidden;
  background-position: center center;
`;

export default StepContainer;
