import { FiMap } from "react-icons/fi";
import { useAtom } from "jotai";
import Button from "../../Button/Button";
import { isMapOpenAtom } from "../../../atoms/mapAtoms";
import useCustomModal from "../../../hooks/useCustomModal";
import Map from "../Map";

const OpenMapButton = ({ ...props }) => {
  const { openModal } = useCustomModal();
  const [isMapOpen, setIsMapOpen] = useAtom(isMapOpenAtom);

  const onOpenMap = () => {
    openModal({
      title: "Carte",
      content: <Map />,
    });
    setIsMapOpen(true);
  };

  return (
    <button className="btn text-primary p-0" onClick={onOpenMap}>
      <FiMap className="me-2" style={{ fontSize: 20 }} />
      {props.text}
    </button>
  );
};

export default OpenMapButton;
