import styled from "styled-components";
import CloseButton from "../../../Common/CustomModal/CloseButton";
import Fab from "../../../Common/Fab/Fab";
import useCustomModal from "../../../hooks/useCustomModal";
import QuizzGauge from "./QuizzGauge";
import QuizzResultsAppreciation from "./QuizzResultsAppreciation";
import useQuizzResults from "./useQuizzResults";

const QuizzResults = () => {
  const {
    hasAnswered,
    nbAnswers,
    nbGoodAnswers,
    nbWrongAnswers,
    nbQuizz,
    getAnswerStatusSentence,
  } = useQuizzResults();

  return (
    <StyledQuizzBlock className="p-3 h-100 overflow-scroll">
      <h1 className="title">Résultats des Quiz</h1>
      <div className="row mt-3">
        <CompletionBlock
          animationDelay={1}
          className="col-12 mb-2  animate__animated animate__fadeIn"
        >
          <p className="gauge-info">
            Quizz complétés : {nbAnswers}/{nbQuizz}
          </p>
          <QuizzGauge value={nbAnswers} max={nbQuizz} color="grey" animationDelay={1.5} />
        </CompletionBlock>
        <CorrectBlock animationDelay={3} className="col-12 mb-2 animate__animated animate__fadeIn ">
          <p className="gauge-info">
            Bonnes réponses : {nbGoodAnswers}/{nbAnswers}
          </p>
          <QuizzGauge value={nbGoodAnswers} max={nbAnswers} color="success" animationDelay={3.5} />
        </CorrectBlock>
        <WrongBlock className="col-12 mb-2  animate__animated animate__fadeIn " animationDelay={5}>
          <p className="gauge-info">
            Mauvaises réponses : {nbWrongAnswers}/{nbAnswers}
          </p>
          <QuizzGauge value={nbWrongAnswers} max={nbAnswers} color="danger" animationDelay={5.5} />
        </WrongBlock>

        <div className="col-12 mt-3">
          <QuizzResultsAppreciation animationDelay={6.5} />
        </div>
      </div>

      <Fab style={{ bottom: 66 }}>
        <CloseButton shouldClearContent />
      </Fab>
    </StyledQuizzBlock>
  );
};

const StyledQuizzBlock = styled.div`
  & .gauge-info {
    font-size: 12px;
    margin-bottom: 0px;
  }
`;

const CompletionBlock = styled.div`
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;
const CorrectBlock = styled.div`
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;
const WrongBlock = styled.div`
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;

export default QuizzResults;
