import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import {
  step1Config,
  step2Config,
  step3Config,
  step4Config,
  step5Config,
  step6Config,
  step7Config,
  step8Config,
} from "./GameSteps/stepsConfig";

export const allImages = [
  {
    id: "hhy_colere",
    file: "002-hhy-colere.webp",
    style: {},
  },

  {
    id: "hhy_joyeux",
    file: "002-hhy-joyeux.webp",
    style: {},
  },
  {
    id: "hhy_neutre",
    file: "002-hhy-neutre.webp",
    style: {},
  },
  {
    id: "foule",
    file: "005-foule.webp",
    style: {},
  },
  {
    id: "hhy_triste",
    file: "002-hhy-triste.webp",
    style: {},
  },
  {
    id: "hhy_douleur",
    file: "002-hhy-douleur.webp",
    style: {},
  },
  {
    id: "prya_neutre",
    file: "003-prya-neutre.webp",
    style: {},
  },

  {
    id: "prya_triste",
    file: "003-prya-triste.webp",
    style: {},
  },
  {
    id: "medecin_neutre",
    file: "004-medecin-neutre.webp",
    style: {},
  },
];

const gameStepsAtom = atom([
  {
    id: 1,
    title: "Etape 1",
    slugName: "etape-1",
    component: "ButtonList",
    dialogs: step1Config,
    images: allImages,
    question:
      "On est venu jusqu'en Chine pour engager des travailleurs, sais-tu pourquoi ?",
    goodAnswer: 1,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "Les planteurs de La Réunion ont besoin de main d'œuvre mais ne peuvent plus faire appel aux Indiens",
        },
        { id: 2, name: "Les chinois sont un peuple plus travailleur." },
        { id: 3, name: "Les chinois sont une main d'œuvre moins chère." },
      ],
    },
  },
  {
    id: 2,
    title: "Etape 2",
    slugName: "etape-2",
    images: allImages,
    dialogs: step2Config,
    component: "ButtonList",
    question:
      "On vous garde en quarantaine dans ce lazaret à La Grande Chaloupe. Selon toi, pourquoi le site de La Grande Chaloupe ?",
    goodAnswer: 2,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "C'est un emplacement au plus près des exploitations",
        },
        {
          id: 2,
          name: "Pour sa position isolée du reste de l'île, afin d'éviter les épidémies",
        },
        { id: 3, name: "C'est le seul débarcadère de l'île" },
      ],
    },
  },
  {
    id: 3,
    title: "Etape 3",
    slugName: "etape-3",
    images: allImages,
    dialogs: step3Config,
    component: "ButtonList",
    question:
      "Le médecin a besoin d'un traducteur pour communiquer avec les nouveaux arrivants. ll doit recruter parmi les résidents déjà présents à La Réunion, mais difficile à trouver. Une solution pas chère était de recruter des personnes parmi...",
    goodAnswer: 3,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "Les origines chrétiennes",
        },
        { id: 2, name: "Les assermentés" },
        { id: 3, name: "L'atelier de discipline des Ponts & Chaussées" },
      ],
    },
  },
  {
    id: 4,
    title: "Etape 4",
    slugName: "etape-4",
    images: allImages,
    dialogs: step4Config,
    component: "ButtonList",
    question: "Aller voir le médecin ne t'arrange pas vraiment, parce que...",
    goodAnswer: 1,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "La quarantaine va être prolongée (on a perdu 10 jours de salaire)",
        },
        {
          id: 2,
          name: "Je n'ai pas confiance dans ce médecin et les remèdes qu'il nous donne",
        },
        { id: 3, name: "Le médecin est raciste" },
      ],
    },
  },
  {
    id: 5,
    title: "Etape 5",
    slugName: "etape-5",
    component: "CheckImage",
    dialogs: step5Config,
    images: allImages,
    question:
      "L'indienne te donne une plante pour soigner ton mal de ventre. Tu la reconnais dans le jardin...",
    goodAnswer: 4,
    xp: 10,
    params: {
      image: "plantes.jpg",
      areas: [
        {
          id: 1,
          x: 0,
          y: 0,
          w: 50,
          h: 50,
          name: "Faham",
        },
        {
          id: 2,
          x: 50,
          y: 0,
          w: 50,
          h: 50,
          name: "Bois puant",
        },
        {
          id: 3,
          x: 0,
          y: 50,
          w: 50,
          h: 50,
          name: "Benjoin",
        },
        {
          id: 4,
          x: 50,
          y: 50,
          w: 50,
          h: 50,
          name: "Yapana",
        },
      ],
    },
  },
  {
    id: 6,
    title: "Etape 6",
    slugName: "etape-6",
    component: "ButtonList",
    dialogs: step6Config,
    images: allImages,
    question:
      "Les engagés sont venus parce qu'on manque de main d'œuvre à La Réunion. Pour quel type de plantations as-tu été recruté ?",
    goodAnswer: 2,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "Café",
        },
        { id: 2, name: "Canne à sucre" },
        { id: 3, name: "Thé" },
      ],
    },
  },
  {
    id: 7,
    title: "Etape 7",
    slugName: "etape-7",
    component: "ButtonList",
    dialogs: step7Config,
    images: allImages,
    question:
      "Cette contestation n'est pas un cas isolé. La colère gronde au sein de la communauté chinoise. En 1908, une révolte éclate. Selon toi, parce que...",
    goodAnswer: 2,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "Les chinois veulent rentrer au pays, La Réunion est trop petite",
        },
        {
          id: 2,
          name: "Les conditions de travail et le racisme sont insupportables",
        },
        {
          id: 3,
          name: "Les chinois pensaient travailler dans le café et pas la canne à sucre",
        },
      ],
    },
  },
  {
    id: 8,
    title: "Etape 8",
    slugName: "etape-8",
    component: "ButtonList",
    dialogs: step8Config,
    images: allImages,
    question:
      "Dans la société réunionnaise, dans quoi se sont principalement spécialisés les anciens engagés chinois ?",
    goodAnswer: 1,
    xp: 10,
    params: {
      datas: [
        {
          id: 1,
          name: "Grossistes et commerces alimentaires",
        },
        { id: 2, name: "Vêtements et textiles" },
        { id: 3, name: "Agriculture" },
      ],
    },
  },
]);

const currentStepInitialState = "1";
export const currentStepAtom = atomWithStorage(
  "LAZARET_JDP_CURRENT_STEP",
  currentStepInitialState
);

const stepsInitialState = {
  1: {
    nbTries: 2,
    isUnlocked: true,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  2: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: [],
  },
  3: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  4: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  5: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  6: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  7: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
  8: {
    nbTries: 2,
    isUnlocked: false,
    isValidated: false,
    isGoodAnswer: false,
    answer: "",
  },
};
export const stepsStateAtom = atomWithStorage(
  "LAZARET_JDP_STEPS_STATE",
  stepsInitialState
);

export const resultsAtom = atom((get) => {
  const gameSteps = get(gameStepsAtom);
  const stepsState = get(stepsStateAtom);
  let goodAnswers = 0;
  let wrongAnswers = 0;
  let score = 0;
  Object.keys(stepsState).forEach((stepId) => {
    const step = stepsState[stepId];
    const gameStep = gameSteps.find((g) => g.id == stepId);
    if (step.isGoodAnswer) {
      goodAnswers += 1;
    } else {
      wrongAnswers += 1;
    }
    score += gameStep.xp * step.nbTries;
  });

  return { goodAnswers, wrongAnswers, score };
});

export const validatedStepsAtom = atomWithStorage({});

export const currentDialogAtom = atom(0);

const useGame = () => {
  const [gameSteps, setGameSteps] = useAtom(gameStepsAtom);

  const [stepsState, setStepsState] = useAtom(stepsStateAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [results] = useAtom(resultsAtom);
  const [currentDialog, setCurrentDialog] = useAtom(currentDialogAtom);

  const onSetCurrentStep = (step) => {
    setCurrentStep(step);
  };

  const onResetGame = () => {
    setStepsState(stepsInitialState);
    setCurrentStep(currentStepInitialState);
  };

  return {
    gameSteps,
    currentStep,
    currentStepRef: gameSteps.find((s) => s.id == currentStep),
    stepsState,
    setStepsState,
    currentStepStateRef: stepsState[currentStep],
    onSetCurrentStep,
    results,
    currentDialog,
    setCurrentDialog,
    onResetGame,
    setGameSteps,
  };
};

export default useGame;
