import { useAtom } from "jotai";
import { activeMarkerAtom, isMapOpenAtom, userPositionAtom } from "../../../atoms/mapAtoms";
import useCustomModal from "../../../hooks/useCustomModal";
import Button from "../../Button/Button";
import Fab from "../../Fab/Fab";
import { BsArrowRight, BsExclamationLg } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import useMarkers from "../Marker/useMarkers";
import useUserPosition from "../useUserPosition";

const MapControls = ({ map }) => {
  const { markers } = useMarkers();
  const { closeModal } = useCustomModal();
  const [isMapOpen, setIsMapOpen] = useAtom(isMapOpenAtom);

  const { userPosition, onWantToLocateUser } = useUserPosition();
  const [activeMarker] = useAtom(activeMarkerAtom);

  const onCloseMap = () => {
    closeModal();
    setIsMapOpen(false);
  };

  const onLocateUser = () => {
    map.current.panTo(userPosition);
    if (!userPosition) {
      onWantToLocateUser();
    }
  };

  const onGoToActiveMarker = () => {
    const markerRef = markers.find((m) => m.id == activeMarker);
    if (markerRef) {
      map.current.panTo(markerRef.lngLat);
    }
  };

  return (
    <Fab>
      <Button
        color="warning"
        className="mb-1"
        onClick={onGoToActiveMarker}
        icon={<BsExclamationLg />}
      />
      <Button
        color="secondary"
        className="mb-1"
        onClick={onLocateUser}
        icon={<BiCurrentLocation />}
      />
      <Button
        color="white"
        className="bg-grey text-white"
        onClick={onCloseMap}
        icon={<BsArrowRight />}
      />
    </Fab>
  );
};

export default MapControls;
