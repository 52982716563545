import useGameStep from "../useGameStep";

const NbTries = () => {
  const { currentStepStateRef } = useGameStep();

  const { nbTries } = currentStepStateRef;
  const isPlural = nbTries > 1;

  return (
    <div className="text-grey text-center">
      <i style={{ fontSize: 14 }}>
        il vous reste {currentStepStateRef.nbTries} essai{isPlural ? "s" : ""}
      </i>
    </div>
  );
};

export default NbTries;
