import useApi from "./hooks/useApi";
import { useEffect } from "react";

const useApp = () => {
  const { apiActions, isLoadingApiDatas } = useApi();

  useEffect(() => {
    apiActions.onGetApiDatas();
  }, []);

  return {
    isLoadingApiDatas,
  };
};

export default useApp;
