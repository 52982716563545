import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import useCustomModal from "../../hooks/useCustomModal";
import Button from "../Button/Button";

const CloseButton = ({ shouldClearContent = false }) => {
  const { closeModal } = useCustomModal();

  const onBtnClick = () => {
    closeModal(shouldClearContent);
  };

  return (
    <CloseBtn
      color="white"
      className="bg-grey text-white"
      onClick={onBtnClick}
      icon={<BsArrowRight />}
    />
  );
};

const CloseBtn = styled(Button)``;

export default CloseButton;
