import { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

const Img = ({ src, ...props }) => {
  const imageRef = useRef();

  useEffect(() => {
    loadImage();
  }, [src]);

  const loadImage = () => {
    var img = new Image();
    img.addEventListener("load", () => {
      imageRef.current.src = src;
      imageRef.current.classList.remove("loading");
      imageRef.current.classList.add("shadow");
      imageRef.current.classList.add("animate__animated");
      imageRef.current.classList.add("animate__fadeIn");
    });
    img.setAttribute("src", src);
  };

  return (
    <StyledImage
      ref={imageRef}
      src="/assets/images/image-placeholder.png"
      className="loading rounded"
      {...props}
    />
  );
};

const imageLoadAnim = keyframes`
0% {
  opacity:0;
}
50% {
  opacity:0.3
}
100% {
  opacity:0
}
`;

const StyledImage = styled.img`
  &.loading {
    animation: ${imageLoadAnim} 3s infinite;
  }
`;

export default Img;
