import { BsArrowRight } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import Button from "../../Common/Button/Button";
import Page from "../../Common/Page/Page";
import useGame from "./useGame";
import { AiTwotoneHome } from "react-icons/ai";

const GamePage = () => {
  const { currentStepRef, onResetGame } = useGame();

  const startBtnParams = {
    text:
      currentStepRef.id > 1 ? (
        <div>
          <div>Continuer le jeu de rôle</div>
          <div style={{ fontSize: 12, fontStyle: "italic" }}>
            {currentStepRef.title}
          </div>
        </div>
      ) : (
        "Commencer le jeu de rôle"
      ),
    href: `/jeu-de-role/debut`,
  };

  const onStartFromScrach = () => {
    onResetGame();
  };

  return (
    <Page title="Jeu de rôle" back="/" contentContainerClassName="px-4">
      <p className="text-justify">
        Ce jeu de rôle interactif plonge les joueurs dans l’histoire de La
        Réunion, à travers les étapes marquantes de l’engagement des
        travailleurs chinois au XIXe siècle. Les joueurs devront prendre des
        décisions stratégiques et répondre à des questions historiques en
        parcourant plusieurs étapes, comme le recrutement en Chine, la
        quarantaine à La Grande Chaloupe, les conditions de travail dans les
        plantations, et la révolte de 1908. Chaque bonne réponse apporte des
        points d’expérience et permet de mieux comprendre l’impact des
        travailleurs chinois sur l’économie et la société réunionnaise.
      </p>
      <Button
        color="success"
        size="lg"
        icon={<BsArrowRight />}
        fullWidth
        className="text-uppercase text-white py-4 fs-2"
        {...startBtnParams}
      />
      {currentStepRef.id > 1 && (
        <Button
          color="link"
          size="md"
          icon={<FiRefreshCcw />}
          fullWidth
          className="text-uppercase text-primary py-4 fs-2"
          text="Réinitialiser le jeu"
          onClick={onStartFromScrach}
        />
      )}
    </Page>
  );
};

export default GamePage;
