import { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useAtom } from "jotai";
import { orderedPositionsAtom } from "../../hooks/atoms/atoms";
import { APP_URL, MAPBOX_TOKEN } from "../../config";

mapboxgl.accessToken = MAPBOX_TOKEN;

const initialMapPosition = {
  lng: 55.375367694633724,
  lat: -20.89795935932986,
};
const initialMapZoom = 17.5;

const useMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [orderedPositions, setOrderedPositions] = useAtom(orderedPositionsAtom);

  console.log("orderedPositions", orderedPositions);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/torkage/cl0f1utux003a14jrl7gr8266",
      //style: "mapbox://styles/mapbox/streets-v11",
      center: [initialMapPosition.lng, initialMapPosition.lat],
      zoom: initialMapZoom,
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("load", function () {
      console.log(
        " orderedPositions.map(pos => pos.lngLat)",
        orderedPositions.map((pos) => pos.lngLat)
      );
      map.current.addSource("markerPath", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [...orderedPositions.map((pos) => pos.lngLat)],
          },
        },
      });
      map.current.loadImage(
        APP_URL + "assets/images/line-pattern.jpg",
        (err, image) => {
          // Throw an error if something goes wrong.
          if (err) throw err;

          // Add the image to the map style.
          map.current.addImage("pattern", image);

          // Create a new layer and style it using `fill-pattern`.
          map.current.addLayer({
            id: "markerPath",
            type: "line",
            source: "markerPath",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-pattern": "pattern",
              "line-width": 9,
            },
          });
        }
      );
      // map.current.addLayer({
      //   id: "markerPath",
      //   type: "line",
      //   source: "markerPath",
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "square",
      //   },
      //   paint: {
      //     "line-color": "#4d4343",
      //     "line-width": 4,
      //   },
      // });

      map.current.resize();
      setIsMapLoaded(true);
    });

    map.current.on("click", (e) => {
      console.log(`clicked at ${e.lngLat}`);
    });
  });

  return { map, mapContainer, isMapLoaded };
};

export default useMap;
