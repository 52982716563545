import { useAtom } from "jotai";
import { getApiDatas } from "../services/apiService";
import {
  apiConstantsAtom,
  apiDatasAtom,
  currentStepAtom,
  isLoadingApiDatasAtom,
} from "./atoms/atoms";

const useApi = () => {
  const [isLoadingApiDatas, setIsLoadingApiDatas] = useAtom(isLoadingApiDatasAtom);
  const [apiDatas, setApiDatas] = useAtom(apiDatasAtom);
  const [apiConstants, setApiConstants] = useAtom(apiConstantsAtom);
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);

  const onGetApiDatas = () => {
    setIsLoadingApiDatas(true);
    getApiDatas()
      .then((data) => {
        if (currentStep) {
          const stepFromId = data.steps.find((step) => step.id == currentStep.id);
          if (!stepFromId) {
            setCurrentStep("");
          }
        }
        setApiDatas(data.steps);
        setApiConstants(data.constants);
      })
      .finally(() => setIsLoadingApiDatas(false));
  };

  return {
    apiDatas,
    apiConstants,
    isLoadingApiDatas,
    apiActions: { onGetApiDatas },
  };
};

export default useApi;
