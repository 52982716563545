import { atom, useAtom } from "jotai";

export const imageModalTitleAtom = atom("");
export const isImageModalOpenAtom = atom(false);
export const imageModalContentAtom = atom(null);

const useImageModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isImageModalOpenAtom);
  const [modalTitle, setModalTitle] = useAtom(imageModalTitleAtom);
  const [modalContent, setModalContent] = useAtom(imageModalContentAtom);

  const openModal = ({ title = null, content = null }) => {
    if (title && typeof title == "string") {
      setModalTitle(title + "");
    }
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const onModalDismiss = () => {
    closeModal();
  };

  return {
    modalTitle,
    setModalTitle,
    modalContent,
    setModalContent,
    isModalOpen,
    setIsModalOpen,
    openModal,
    closeModal,
    onModalDismiss,
  };
};

export default useImageModal;
