import { useParams } from "react-router-dom";
import useAudioPlayers from "../../../Common/AudioPlayers/useAudioPlayers";
import { tools } from "../../../utils/tools";
import useGame from "../useGame";

const useGameStep = () => {
  const {
    gameSteps,
    stepsState,
    setStepsState,
    currentStepRef,
    currentStepStateRef,
    onSetCurrentStep,
    currentDialog,
    setCurrentDialog,
    setGameSteps,
  } = useGame();
  const { slugName } = useParams();
  const { playSound } = useAudioPlayers();

  const nextStep = gameSteps.find((step) => step.id == currentStepRef.id + 1);
  const prevStep = gameSteps.find((step) => step.id == currentStepRef.id - 1);

  const onUpdateStepsState = (newStepsStates) => {
    setStepsState(newStepsStates);
  };

  const handleAnswerChange = (e) => {
    const { value } = e.target;
    onUpdateStepsState({
      ...stepsState,
      [currentStepRef.id]: {
        ...currentStepStateRef,
        answer: value,
        isValidated: false,
      },
    });
  };

  const validateAnswer = () => {
    let isGoodAnswer = false;
    switch (currentStepRef.component) {
      case "ButtonList":
        isGoodAnswer = currentStepStateRef.answer == currentStepRef.goodAnswer;
        break;
      case "CheckList":
        isGoodAnswer =
          JSON.stringify(
            currentStepStateRef.answer.sort((a, b) => (a > b ? 1 : -1))
          ) ==
          JSON.stringify(
            currentStepRef.goodAnswer.sort((a, b) => (a > b ? 1 : -1))
          );
        break;
      case "TextHole":
        isGoodAnswer = tools.compareNormalized(
          currentStepStateRef.answer,
          currentStepRef.goodAnswer
        );
        break;
      case "CheckImage":
        isGoodAnswer = currentStepStateRef.answer == currentStepRef.goodAnswer;
        break;
      default:
        break;
    }
    const newNbTries = currentStepStateRef.nbTries - 1;
    let otherParams = {};
    //Déterminer s'il faut déverouiller l'étape suivante (bonne réponse ou nb d'essaies épuisé)
    if ((isGoodAnswer || newNbTries == 0) && nextStep?.id) {
      const nextStepState = stepsState[nextStep.id];
      otherParams = {
        [nextStep.id]: { ...nextStepState, isUnlocked: true },
      };
    }

    playSound(isGoodAnswer ? "WIN" : "LOSE");

    onUpdateStepsState({
      ...stepsState,
      [currentStepRef.id]: {
        ...currentStepStateRef,
        isGoodAnswer,
        nbTries: newNbTries,
        isValidated: true,
      },
      ...otherParams,
    });
  };

  //déterminer l'input a été complètement rempli
  let isAnswerGiven = false;
  switch (currentStepRef.component) {
    case "ButtonList":
      isAnswerGiven = currentStepStateRef.answer;
      break;
    case "CheckList":
      isAnswerGiven =
        currentStepStateRef.answer.length == currentStepRef.goodAnswer.length;
      break;
    case "TextHole":
      isAnswerGiven =
        currentStepStateRef.answer.length == currentStepRef.goodAnswer.length;
      break;
    case "CheckImage":
      isAnswerGiven = currentStepStateRef.answer;
      break;
    default:
      break;
  }

  const viewConditions = {
    isInputDisabled:
      currentStepStateRef.isGoodAnswer || currentStepStateRef.nbTries == 0,
    canValidateAnswer:
      isAnswerGiven &&
      currentStepStateRef.nbTries > 0 &&
      !currentStepStateRef.isGoodAnswer,
    canSeeNbTries: !currentStepStateRef.isGoodAnswer,
    canGoToNextStep:
      currentStepStateRef.isGoodAnswer || currentStepStateRef.nbTries == 0,
    canSeeAnswerResult: currentStepStateRef.isValidated,
    isLastDialog: currentDialog == currentStepRef.dialogs.length,
  };

  const onNextDialog = () => {
    setCurrentDialog((d) => d + 1);
  };
  const onPrevDialog = () => {
    if (currentDialog > 0) {
      setCurrentDialog((d) => d - 1);
    }
  };

  const onUpdateStepConfig = (newConfig) => {
    setGameSteps(
      gameSteps.map((step) =>
        step.id == currentStepRef.id ? { ...step, dialogs: newConfig } : step
      )
    );
  };

  const currentDialogRef = currentStepRef.dialogs[currentDialog];

  return {
    gameSteps,
    stepsState,
    currentStepRef,
    currentStepStateRef,
    handleAnswerChange,
    slugName,
    isAnswerGiven,
    validateAnswer,
    nextStepLink: `/jeu-de-role/${nextStep?.slugName || "fin"}`,
    prevStepLink: `/jeu-de-role/${prevStep?.slugName || ""}`,
    viewConditions,
    currentDialog,
    currentDialogRef,
    onNextDialog,
    onPrevDialog,
    onSetCurrentStep,
    setCurrentDialog,
    onUpdateStepConfig,
  };
};

export default useGameStep;
