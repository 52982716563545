import Button from "../../../../Common/Button/Button";
import useGameStep from "../useGameStep";

const ValidButton = () => {
  const { validateAnswer } = useGameStep();

  return (
    <Button
      color="success"
      className="btn-lg w-100 "
      text="Valider ma réponse"
      onClick={validateAnswer}
    />
  );
};

export default ValidButton;
