import styled from "styled-components";
import { tools } from "../../../../utils/tools";
import ControlInfo from "../ControlInfo/ControlInfo";

const TextHole = ({
  value,
  name,
  onChange,
  textToFind,
  beforeText,
  afterText,
  showErrors,
  disabled,
}) => {
  const handleChange = (e) => {
    var val = e.target.value;
    if (val.length < value.length || value.length < textToFind.length) {
      onChange({
        target: {
          name,
          value: e.target.value,
        },
      });
    }
  };

  const getHoleValue = () => {
    var underscoreSize = textToFind.length - value.length;
    let val = value.split("");

    return (
      <>
        {val.map((v, vk) => {
          let valCN = "";
          if (showErrors) {
            valCN = tools.compareNormalized(v, textToFind[vk]) ? "text-success" : "text-danger";
          }
          return (
            <span className={`val-char ${valCN}`} key={`txth${vk}`}>
              {v}
            </span>
          );
        })}
        <i className="curs animate__animated animate__flash animate__slow animate__infinite" />
        {Array(underscoreSize)
          .fill(1)
          .map((o) => (
            <span className="val-char">_</span>
          ))}
      </>
    );
  };

  return (
    <div>
      {!disabled && (
        <ControlInfo
          text={`Touchez la phrase puis écrivez un mot de ${textToFind.length} caractères`}
        />
      )}

      <StyledLabel htmlFor="textHole" className="rounded p-3 w-100 bg-secondary">
        {beforeText} {getHoleValue()} {afterText}
        <input
          type="text"
          name="textHole"
          id="textHole"
          value={value}
          onChange={handleChange}
          className="visually-hidden"
          disabled={disabled ? "disabled" : false}
        />
      </StyledLabel>
    </div>
  );
};

const StyledLabel = styled.label`
  & .val-char {
    letter-spacing: 1.5px;
    color: var(--bs-blue);
    font-family: monospace;
    padding: 0px 0.8px;
    display: inline-block;
  }

  &:focus,
  &:focus-within {
    outline: 2px solid var(--bs-primary);
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    & .curs {
      visibility: visible;
    }
  }

  & .curs {
    visibility: hidden;
    display: inline-block;
    width: 2px;
    height: 12px;
    background-color: var(--bs-blue);
    position: relative;
    top: 0px;
  }
`;

export default TextHole;
