import { keyframes } from "styled-components";

export const pulse = keyframes`
0% {
  transform:scale(1)
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`;

export const pulseOut = keyframes`
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity:1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.4);
      opacity: 0;
    }
`;
export const arcOut = keyframes`
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity:1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.4);
      opacity: 0;
    }
`;
