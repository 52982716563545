import { atom, useAtom } from "jotai";

export const modalTitleAtom = atom("");
export const isModalOpenAtom = atom(false);
export const modalContentAtom = atom(null);

const useCustomModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const [modalTitle, setModalTitle] = useAtom(modalTitleAtom);
  const [modalContent, setModalContent] = useAtom(modalContentAtom);

  const openModal = ({ title = null, content = null }) => {
    if (title && typeof title == "string") {
      setModalTitle(title + "");
    }
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = (shouldClearContent) => {
    setIsModalOpen(false);
    if (shouldClearContent) {
      setModalContent(null);
    }
  };

  const onModalDismiss = () => {
    closeModal();
  };

  return {
    modalTitle,
    setModalTitle,
    modalContent,
    setModalContent,
    isModalOpen,
    setIsModalOpen,
    openModal,
    closeModal,
    onModalDismiss,
  };
};

export default useCustomModal;
