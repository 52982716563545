import dataService from "../utils/dataService";

export const getApiDatas = () => {
  return new Promise((resolve, reject) => {
    dataService.get({
      url: `steps/public`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};
