import { BsArrowRight } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Button from "../../Common/Button/Button";
import Page from "../../Common/Page/Page";
import VisitStepList from "./VisitStepList/VisitStepList";
import useVisitStepList from "./VisitStepList/useVisitStepList";
import { useAtom } from "jotai";
import { nbStepsAtom } from "../../hooks/atoms/atoms";

const VisitPage = () => {
  const [nbSteps] = useAtom(nbStepsAtom);
  const { currentStep, visitedSteps, getLastVisitedStep, steps } = useVisitStepList();

  const lastVisitedStep = getLastVisitedStep();

  const startBtnParams = {
    text: lastVisitedStep ? (
      <div>
        <div>Continuer la visite</div>
        <div style={{ fontSize: 12, fontStyle: "italic" }}>{lastVisitedStep.title}</div>
      </div>
    ) : (
      "Commencer la visite"
    ),
    href: `/visite-guidee/etape/${lastVisitedStep?.slugName || steps[0].slugName}`,
  };

  return (
    <Page title="Étapes de la visite" back="/" contentContainerClassName="px-4">
      <p className="text-justify">
        Ce petit guide virtuel vous prend par la main et vous guide à travers le Lazaret de la
        Grande Chaloupe. En {nbSteps} étapes, voyagez à travers le temps et l'espace pour découvrir
        ce lieu riche en histoire. Des anecdotes, des images et des quiz viendront pimenter cette
        expérience, tout au long de votre parcours. Et si vous êtes perdus, n'hésitez pas à vous
        servir de la carte interactive ! <br />
        Bonne visite !
      </p>
      <Button
        color="success"
        size="lg"
        icon={<BsArrowRight />}
        fullWidth
        className="text-uppercase text-white py-4 fs-2"
        {...startBtnParams}
      />

      <p className="my-3 fs-4 text-center">
        <i>OU</i>
      </p>
      <p className="fs-5 text-center">Accéder directement à une étape</p>
      <VisitStepList />
    </Page>
  );
};

export default VisitPage;
