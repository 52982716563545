import IndexPage from "./Pages/IndexPage/IndexPage";
import { AiTwotoneHome } from "react-icons/ai";
import VisitPage from "./Pages/VisitPage/VisitPage";
import StepPage from "./Pages/StepPage/StepPage";
import VisitEndPage from "./Pages/VisitEndPage/VisitEndPage";
import GamePage from "./Pages/GamePage/GamePage";
import GameStep from "./Pages/GamePage/GameSteps/GameStep";
import GameEnd from "./Pages/GamePage/GameSteps/GameEnd/GameEnd";
import GameStart from "./Pages/GamePage/GameSteps/GameStart/GameStart";
import GameScore from "./Pages/GamePage/GameSteps/GameScore/GameScore";

export const routesConfig = [
  {
    type: "public",
    path: "/",
    title: "Accueil",
    inNavbar: true,
    element: <IndexPage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/visite-guidee",
    title: "Étapes de la visite",
    inNavbar: true,
    element: <VisitPage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/visite-guidee/etape/:slugName",
    title: "Étape de la visite",
    inNavbar: true,
    element: <StepPage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/visite-guidee/fin",
    title: "Fin de de la visite",
    inNavbar: true,
    element: <VisitEndPage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/jeu-de-role",
    title: "Fin de de la visite",
    inNavbar: true,
    element: <GamePage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/jeu-de-role/score",
    title: "Fin du jeu de rôle",
    inNavbar: true,
    element: <GameScore />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/jeu-de-role/fin",
    title: "Fin du jeu de rôle",
    inNavbar: true,
    element: <GameEnd />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/jeu-de-role/debut",
    title: "Début du jeu de rôle",
    inNavbar: true,
    element: <GameStart />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "/jeu-de-role/:slugName",
    title: "Etape du jeu de rôle",
    inNavbar: true,
    element: <GameStep />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "public",
    path: "*",
    title: "Page introuvable",
    inNavbar: true,
    element: <IndexPage />,
    icon: <AiTwotoneHome />,
  },
];
