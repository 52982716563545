import React from "react";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import styled from "styled-components";
import Button from "../../../../Common/Button/Button";
import ControlInfo from "../ControlInfo/ControlInfo";

const CheckList = ({ datas, value, onChange, name, showErrors, goodAnswer, ...props }) => {
  const handleChange = (id) => {
    let newValue = [...value];
    if (value.includes(id)) {
      newValue = newValue.filter((v) => v !== id);
    } else newValue.push(id);
    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  const commonProps = {
    name: name,
    fullWidth: true,
    className: " py-2 d-flex justify-content-start align-items-center",
    iconSlot: "before",
  };
  const activeProps = {
    color: "primary",
    icon: <BsCheck2Circle />,
    ...commonProps,
  };
  const inactiveProps = {
    color: "secondary",
    icon: <BsCircle />,
    ...commonProps,
  };

  return (
    <StyledCheckList>
      {!props.disabled && <ControlInfo text={`Cochez ${goodAnswer.length} réponses`} />}

      {datas.map((data, dk) => {
        const btnProps = value.includes(data.id) ? activeProps : inactiveProps;

        return (
          <Button
            onClick={() => handleChange(data.id)}
            text={data.name}
            key={`btnl${dk}`}
            {...btnProps}
            {...props}
          />
        );
      })}
    </StyledCheckList>
  );
};

const StyledCheckList = styled.div`
  & button {
    margin-bottom: 12px;
    min-height: 48px;
  }
  & button:last-child {
    margin-bottom: 0px;
  }
`;

export default CheckList;
