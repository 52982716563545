import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { routesConfig } from "./routesConfig";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {routesConfig.map((route) => (
          <Route key={`route${route.title}`} path={route.path} element={route.element} />
        ))}
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
