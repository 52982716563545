import ImageSliderView from "../../../Common/ImageSliderView/ImageSliderView";
import useImageModal from "../../../hooks/useImageModal";

const ImagesBlock = ({ data }) => {
  const { openModal } = useImageModal();

  const onImageClick = (imageIndex) => {
    openModal({
      title: data.title,
      content: (
        <ImageSliderView
          images={data.files}
          containerCN="h-100"
          imageMaxHeight="80vh"
          imageContainerHeight="85vh"
          initialSlide={imageIndex}
        />
      ),
    });
  };

  return (
    <div className="w-100">
      <ImageSliderView slidesPerView={2} images={data.files} onImageClick={onImageClick} />
    </div>
  );
};

export default ImagesBlock;
