import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const QuizzGauge = ({ value, max, color, animationDelay = 0 }) => {
  let counter = 0;
  const pct = Math.round((value / max) * 100);
  const counterTime = 1500 / pct;

  const gaugePctRef = useRef(null);

  useEffect(() => {
    counter = 0;
    setTimeout(() => {
      incrementCounter();
    }, animationDelay * 1000);
  }, []);

  const incrementCounter = () => {
    if (gaugePctRef.current) {
      gaugePctRef.current.innerHTML = counter + " %";
      if (counter < pct) {
        counter++;
        setTimeout(() => incrementCounter(), counterTime);
      }
    }
  };

  return (
    <StyleGauge className={`shadow bg-gradient d-center  border-${color}`}>
      <GaugeBlock
        animationDelay={animationDelay}
        color={color}
        pct={pct}
        className={`gauge animate__animated animate__fadeInLeft animate__slow bg-gradient `}
      ></GaugeBlock>
      <span className="position-relative" ref={gaugePctRef}></span>
    </StyleGauge>
  );
};

const StyleGauge = styled.div`
  height: 20px;
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  font-size: 12px;

  & span {
    z-index: 9;
    filter: drop-shadow(1px 1px 3px black);
  }
`;
const GaugeBlock = styled.div`
  width: ${({ pct }) => pct}%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--bs-${({ color }) => color});
  font-size: 12px;
  animation-delay: ${({ animationDelay }) => animationDelay}s;
`;

export default QuizzGauge;
