import React from "react";
import styled, { keyframes } from "styled-components";

const tints = {
  brown: {
    back: "rgba(51,5,5, 0.23)",
    front: "rgba(51,5,5, 0.71)",
  },
  green: {
    back: "rgba(0, 128, 0, 0.23)",
    front: "rgba(0, 128, 0, 0.71)",
  },
  red: {
    back: "rgba(128, 0, 0, 0.23)",
    front: "rgba(128, 0, 0, 0.71)",
  },
};

const ScoreBox = ({ score, total, tint, text, size }) => {
  var progressPct = score / total;

  return (
    <StyledScoreBox
      tint={tints[tint]}
      progressPct={progressPct}
      size={size}
      className="d-center flex-column"
    >
      <div className="p-3 d-center score-progress">
        <span className="score-number">{score}</span>
      </div>
      <span className="score-text">{text}</span>
    </StyledScoreBox>
  );
};
const scoreAnim = (pct) => keyframes`
0% {
  transform:scaleY(0);
}
100% {
  transform: scaleY(${pct});
}
`;

const StyledScoreBox = styled.div`
  & .score-progress {
    position: relative;
    border-radius: 50%;
    border: 2px solid ${({ tint }) => tint.front};
    background: ${({ tint }) => tint.back};
    color: white;
    font-weight: 800;
    font-size: 50px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    overflow: hidden;
    & .score-number {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      background-color: ${({ tint }) => tint.front};
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform-origin: bottom;
      animation-name: ${(props) => scoreAnim(props.progressPct)};
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }

  & .score-text {
    font-size: 18px;
    font-weight: 800;
    margin-top: 10px;
    color: ${({ tint }) => tint.front};
  }
`;

export default ScoreBox;
