import "mapbox-gl/dist/mapbox-gl.css";
import useMap from "./useMap";
import UserPositionMarker from "./UserPositionMarker";
import Marker from "./Marker/Marker";
import styled from "styled-components";
import MapControls from "./MapControls/MapControls";
import useMarkers from "./Marker/useMarkers";

const Map = () => {
  const { map, mapContainer, isMapLoaded } = useMap();
  const { markers } = useMarkers();

  return (
    <>
      <StyledMap ref={mapContainer} />

      {isMapLoaded && (
        <>
          <MapControls map={map} />
          <div style={{ display: "none" }}>
            <UserPositionMarker map={map} />
            {markers.map((marker) => (
              <Marker key={`mrk${marker.id}`} map={map} {...marker} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const StyledMap = styled.div`
  height: 100%;
`;

export default Map;
