import { useEffect } from "react";
import { ANIM_TYPES } from "./stepsConfig";
import useGameStep from "./useGameStep";

export const characterImagesFolder = "/assets/images/game/characters/";

const StepCharacters = () => {
  const { currentStepRef, currentDialog, currentDialogRef } = useGameStep();
  const images = currentStepRef.images;

  useEffect(() => {
    if (currentDialogRef?.imageAnimations?.length > 0) {
      currentDialogRef.imageAnimations.forEach((image) => {
        var imageElem = document.querySelector(`#dialog-image-${image.id}`);
        if (!imageElem) return null;
        switch (image.type) {
          case ANIM_TYPES.PLAY_ANIMATION:
            imageElem.className = `animate__animated ${image.animation
              .split(" ")
              .map((anim) => `animate__${anim}`)
              .join(" ")}`;

            if (image.style) {
              imageElem.style = "";
              Object.keys(image.style).forEach((attr) => {
                imageElem.style.position = "absolute";
                imageElem.style[attr] = image.style[attr];
              });
            }
            break;
          case ANIM_TYPES.REPLACE_IMAGE:
            if (image.animation) {
              imageElem.className = `animate__animated ${image.animation
                .split(" ")
                .map((anim) => `animate__${anim}`)}`;
            }
            if (image.replaceWithImageId) {
              var replacementImageRef = currentStepRef.images.find(
                (i) => i.id == image.replaceWithImageId
              );
              imageElem.src = characterImagesFolder + replacementImageRef.file;
            }

            if (image.style) {
              imageElem.style = "";
              Object.keys(image.style).forEach((attr) => {
                imageElem.style.position = "absolute";
                imageElem.style[attr] = image.style[attr];
              });
            }
            break;

          default:
            break;
        }
      });
    }
  }, [currentDialog]);

  if (!images || images.length == 0) {
    return null;
  }

  return (
    <div>
      {images.map((img) => {
        return (
          <img
            key={img.id}
            alt={img.id}
            id={`dialog-image-${img.id}`}
            className="d-none"
            src={`${characterImagesFolder}${img.file}`}
            style={{
              position: "absolute",
              maxHeight: "75%",
              maxWidth: "50%",
            }}
          />
        );
      })}
    </div>
  );
};

export default StepCharacters;
