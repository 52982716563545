import styled from "styled-components";
import useCustomModal from "../../hooks/useCustomModal";
import paperTexture from "../../theme/assets/images/paper-texture.png";
import Fab from "../Fab/Fab";
import CloseButton from "./CloseButton";

const CustomModal = () => {
  const { isModalOpen, modalTitle, modalContent, onModalDismiss, closeModal } = useCustomModal();

  return (
    <StyledCustomModal className="bg-secondary" isOpen={isModalOpen}>
      {modalContent}
    </StyledCustomModal>
  );
};

const StyledCustomModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "100vw")});
  transition: all 400ms;
  background-image: url(${paperTexture});
`;

export default CustomModal;
