import { BsVolumeMute, BsVolumeUp } from "react-icons/bs";
import useAudioPlayers from "../../../Common/AudioPlayers/useAudioPlayers";

const MuteSoundButton = () => {
  const { toggleSound, isSoundMuted } = useAudioPlayers();

  return (
    <button className="btn text-primary p-0 d-flex" style={{ fontSize: 22 }} onClick={toggleSound}>
      {isSoundMuted ? <BsVolumeMute /> : <BsVolumeUp />}
    </button>
  );
};

export default MuteSoundButton;
