import { AiOutlineQuestion } from "react-icons/ai";
import styled from "styled-components";
import { keyframes } from "styled-components";

const QuestionSeparator = () => {
  return (
    <StyledSeparator className="w-100 position-relative px-0 mb-1">
      <Rotator />
      <AiOutlineQuestion />
    </StyledSeparator>
  );
};

const iconAnim = keyframes`
0% {
  transform:translate(-50%, -50%) rotate(0deg)
}
100% {
  transform:translate(-50%, -50%) rotate(360deg)
}
`;

const Rotator = styled.div`
  background: var(--bs-primary);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation-name: ${iconAnim};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const StyledSeparator = styled.div`
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    color: white;
  }
  &:before,
  &:after {
    content: "";
    background: var(--bs-primary);
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
`;

export default QuestionSeparator;
