import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Loader from "../../../../Common/Loader/Loader";
import Page from "../../../../Common/Page/Page";
import useImagePreloader from "../../../../hooks/useImagePreloader";
import useGame, { allImages } from "../../useGame";
import MuteSoundButton from "../MuteSoundButton";

const GameStart = () => {
  const navigate = useNavigate();
  const { gameSteps, currentStepRef } = useGame();
  const { imagesPreloaded, loadImages } = useImagePreloader();

  useEffect(() => {
    loadImages([
      ...allImages.map((i) => "/assets/images/game/characters/" + i.file),
      "/assets/images/game/steps/step1.webp",
      "/assets/images/game/steps/step2.webp",
      "/assets/images/game/steps/step3.webp",
      "/assets/images/game/steps/step4.webp",
      "/assets/images/game/steps/step5.webp",
      "/assets/images/game/steps/step6.webp",
      "/assets/images/game/steps/step7.webp",
      "/assets/images/game/steps/step8.webp",
    ]);
  }, []);

  useEffect(() => {
    if (imagesPreloaded) {
      setTimeout(() => {
        navigate(
          `/jeu-de-role/${currentStepRef?.slugName || gameSteps[0].slugName}`
        );
      }, 7000);
    }
  }, [imagesPreloaded]);

  return (
    <Page title={"  "} end={<MuteSoundButton />}>
      {!imagesPreloaded && (
        <div className="d-center h-100 w-100 flex-column">
          <Loader />
          <div>Chargement en cours...</div>
        </div>
      )}
      {imagesPreloaded && (
        <StyledContainer className="d-center">
          <div className="title animate__animated animate__fadeIn animate__slower">
            Comment l'engagisme a t-il impacté l'île de La Réunion ?
          </div>
        </StyledContainer>
      )}
    </Page>
  );
};

const bgAnim = keyframes`
0% {
  background-position:0%
}
100% {
  background-position:100%;
}
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background: url(/assets/images/game/steps/step1.webp);
  background-repeat: no-repeat;
  padding: 30px;
  animation-name: ${bgAnim};
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  & .title {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #763c14;
    background: rgba(245, 216, 177, 0.77);
    font-size: 30px;
    line-height: 38px;
    font-weight: 800;
    top: 0px;
    padding: 10px;
  }
`;

export default GameStart;
